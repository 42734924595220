var screenType =  {
	sliceFilters: [],
		slicePath: '',
		$toolbarColumn: null,
		scanDate: '',
		slices: [],
		sliceThickness: 1,
		maxSlices: 5,
		caseId: '',
		density: 'lung',
		orientation: 'transversal',
		preloadKey: '',
		maxSliceIndex: 10000,
		$screen: null,
		screenIndex: 0,
		sliceIndex: 0,
	 	zoomFactor: 1,
		panningOffset:{
			x:0,
			y:0
		},
		nodules:[],
		loadingSlices: false,
		sliderIsSetup: false,
		canvasInitiated:false,
		/** see the nodules before you found them **/
		sneakPeekMode: false,
		init: function() {

			// setup screen listeners
			$(this).mouseZoomHandler({
				zoomCallback: this.setZoomFactor,
				panCallback: this.setPanning,
				$clickContainer: $(this.$screen).find('.node-container canvas')[0]
			});

			$('.scanner').trigger({type:'set-scan-date', screen: this});

		},
		loadSlice: function (screen, sliceIndex) {
		if (sliceIndex < 0 ) {
			return;
		}

		var orientationCode = 'CC';
		if (screen.orientation == 'frontal') {
			orientationCode = 'AP';
		} else if (screen.orientation == 'sagital') {
			orientationCode = 'SD';
		}

		// add mip to orientation
		if (screen.sliceThickness > 1) {
			orientationCode = orientationCode + '-MIP-10';
		}

		console.log("screen info: ", screen);





		var slicePath = scannerRepo.slicePathMask
			// .replace('{caseId}', 'C' + scannerRepo.addPaddingZeros(screen.caseId, 3))
			.replace('{caseId}', screen.caseId)
			.replace('{scanDate}', screen.scanDate)
			.replace('{orientation}', orientationCode)
			.replace('{density}',screen.density);

		if (screen.slicePath != slicePath) {


			var testPreloadKey = screen.density + screen.orientation + screen.sliceThickness + screen.scanDate;
			if (scannerRepo.preloaded[testPreloadKey] === undefined) {
				// return after detecting the slices
				scannerRepo.loadSlices(screen, slicePath, screen.getCallbackLoadSlices(sliceIndex, function(){
					// // console.log('place iets');
						screen.placeSlices(screen, sliceIndex);


				}));
				return;

			} else {

				var setupScreenFunction = screen.getCallbackLoadSlices(sliceIndex);

				screen.preloadKey = testPreloadKey;
				screen.slicePath = slicePath;
				setupScreenFunction(screen);

				// scannerRepo.setupSlider(screen);
				// scannerRepo.setupCanvas(screen, function (screen) {
				// 	screen.loadSlice(screen, sliceIndex);
				// });
			}
		}
		if (sliceIndex >= screen.slices.length) {
			return;
		}

		if ( screen.slices.length == 0 ) {
			return;
		}

		if (screen.slices[sliceIndex]) {
			screen.placeSlices(screen, sliceIndex);
		}
	},

	getCallbackLoadSlices: function (sliceIndex, firstResultCallback) {

		return function (screen) {
			if (screen.density + screen.orientation + screen.sliceThickness + screen.scanDate == screen.preloadKey) {
				return;
			}

			screen.preloadKey = screen.density + screen.orientation + screen.sliceThickness + screen.scanDate;

			if (scannerRepo.preloaded[screen.preloadKey] === undefined) {

				scannerRepo.preloaded[screen.preloadKey] = [];

				$(screen.slices).preload({
					path: screen.slicePath,
					destination: scannerRepo.preloaded[screen.preloadKey],
					startKey: sliceIndex,
					firstResultCallback: firstResultCallback
				});
			}

			scannerRepo.setupSlider(screen);
			scannerRepo.setupCanvas(screen, function (screen) {
				screen.loadSlice(screen, sliceIndex);
			});
		}
	},


	showLoading: function(isLoading){
		var screen = this;
		screen.loadingSlices = isLoading;
		screen.$screen.toggleClass('loading', isLoading);
	},
	getSliceUrl: function (caseId, sliceIndex, density, orientation, scanDate, mip) {
		var numberOfDomains = scannerRepo.domains.length;
		var sliceIndexOffset = 0;

		//sliceIndexOffset = 7799;
		var slice = scannerRepo.addPaddingZeros(sliceIndex, 4);
		// var caseCode = 'C' + scannerRepo.addPaddingZeros(caseId, 3);
		var orientationCode = 'CC';
		if (orientation == 'frontal') {
			orientationCode = 'AP';
		} else if (orientation == 'sagital') {
			orientationCode = 'SD';
		}

		if (mip) {
			orientationCode = orientationCode + '-MIP-10';
		}
console.log( scannerRepo.domains[ sliceIndex % numberOfDomains] + scannerRepo.scanUrlMask.replace('{caseId}', caseId).replace('{sliceIndex}', slice).replace('{density}', density).replace('{orientation}', orientationCode).replace('{scanDate}', scanDate));
		return scannerRepo.domains[ sliceIndex % numberOfDomains] + scannerRepo.scanUrlMask.replace('{caseId}', caseId).replace('{sliceIndex}', slice).replace('{density}', density).replace('{orientation}', orientationCode).replace('{scanDate}', scanDate);
		//return scannerRepo.domains[ sliceIndex % numberOfDomains] + scannerRepo.scanUrlMask.replace('{caseId}', caseId).replace('{sliceIndex}', (sliceIndex+sliceIndexOffset)).replace('{density}', density);
	},

	placeSlices:function(screen, topIndex){

		// console.log('place slices', topIndex);
		var $slice = screen.$screen.find('.slice');
		var myCanvasElem = $slice.get(0);
		var context = myCanvasElem.getContext('2d');

		var preloadImage = scannerRepo.preloaded[screen.preloadKey][topIndex];
		// // console.log('preloaded',scannerRepo.preloaded[screen.preloadKey][topIndex]);
		scannerRepo.loadCanvasLayer(myCanvasElem, context, screen.getSliceUrl(screen.caseId, topIndex, screen.density, screen.orientation, screen.scanDate, screen.sliceThickness > 1), false, preloadImage);

		screen.sliceIndex = topIndex;
	},
	executeSliceFilters: function (screen, $slice) {

		$(screen.sliceFilters).each(
			function(){
				var $filterSlice = $slice;
				return function (index, filter) {
					filter.execute($filterSlice);
				};
			}()
		);
	},
	getScrollHandler: function (currentlyScrollingScreen) {
		var screen = currentlyScrollingScreen;
		return function (event) {
			event.preventDefault();
			if (event.originalEvent.detail > 0 || event.originalEvent.wheelDelta < 0) {
				screen.gotoSlice(screen, screen.sliceIndex + 1);
			} else {
				screen.gotoSlice(screen, screen.sliceIndex - 1);
			}

			//prevent page fom scrolling
			return false;
		}
	},

	scrollOnSlide: function (direction, currentScreen) {
		var screen = currentScreen;
		if(direction === 'up') {
			screen.gotoSlice(screen, screen.sliceIndex - 1);
		} else {
			screen.gotoSlice(screen, screen.sliceIndex + 1);
		}
	},

	gotoSlice: function(screen, sliceIndex, isSynchronisationCall, forced) {

		if (!forced){
			forced = false;
		}

		if (screen.sliceIndex == sliceIndex && !forced ) {
			return;
		}
		screen.loadSlice(screen, sliceIndex);
		screen.$screen.find('.slider').slider({value: -screen.sliceIndex});


		screen.$screen.trigger('slider-change', {value: -screen.sliceIndex});

		if (!isSynchronisationCall){
			if ($('.scanner.syncScrollMode').length){
				var scanner = $('.scanner').data('scanner');
				var scrollDifference = scanner.settings.syncScrollDifference;
				if (screen.screenIndex == 1){
					scrollDifference = -1 * scrollDifference;
				}
				$(scanner.settings.screens).each(function(key, screenObject) {
					if( screenObject.screenIndex == screen.screenIndex) {
						return;
					}
					screenObject.gotoSlice(screenObject, sliceIndex + scrollDifference, true);
				});
			}
		}
	},
	/**
	 * set the zoomfactor
	 * @param screen
	 * @param zoomFactor
	 * @param zoomFocusRatio
	 * @param originalEvent
	 */
	setZoomFactor:function(screen, zoomFactor, zoomFocusRatio, focusPositionScreen, originalEvent){
		screen.zoomFactor += zoomFactor;
		screen.zoomFactor = Math.min(10, Math.max(1, Math.abs(screen.zoomFactor)));

		var $canvasContainer = screen.$screen.find('.canvascontainer');
		$canvasContainer.css('transform', 'scale(' + screen.zoomFactor+')');
		$canvasContainer.attr('data-zoomfactor', screen.zoomFactor);


		if (!zoomFocusRatio) {

			$canvasContainer.css('left', 0);
			$canvasContainer.css('top', 0);
			screen.panningOffset.x = 0;
			screen.panningOffset.y = 0;

			return;
		}

		var canvasRectangle = originalEvent.target.getBoundingClientRect();
		var canvasFocusPosition = {
			x: zoomFocusRatio.x,
			y: zoomFocusRatio.y
		};

		// console.log('zoom:', canvasFocusPosition, canvasRectangle);

		var screenPositionFocalPoint = {
			x: 0,
			y: 0
		};

		 screenPositionFocalPoint.x = (canvasRectangle.left ) + (canvasRectangle.width * zoomFocusRatio.x);
		 screenPositionFocalPoint.y = (canvasRectangle.top ) + (canvasRectangle.height * zoomFocusRatio.y);

		// dempings factor 0.2 ivm overshoot
		var diffX  = ( screenPositionFocalPoint.x  - focusPositionScreen.x  ) ;
		var diffY  = ( screenPositionFocalPoint.y - focusPositionScreen.y  ) ;



		// console.log( 'focussverschil',screenPositionFocalPoint,focusPositionScreen );
		// // fix position


		screen.panningOffset.x -= diffX;
		screen.panningOffset.y -= diffY;
		$canvasContainer.css('left', screen.panningOffset.x );
		$canvasContainer.css('top', screen.panningOffset.y );

	},
	/**
	 * set Panning to screen
	 *
	 * @param screen
	 * @param offsetX
	 * @param offsetY
	 */
	setPanning:function(screen, offsetX, offsetY) {

		screen.panningOffset.x += offsetX/screen.zoomFactor;
		screen.panningOffset.y += offsetY/screen.zoomFactor;
		if (screen.zoomFactor == 1) {
			screen.panningOffset.x = 0;
			screen.panningOffset.y = 0;
		}

		screen.$screen.find('.canvascontainer')
			.css('left', screen.panningOffset.x)
			.css('top', screen.panningOffset.y);
	}


};
window.screenType = screenType;