
require ('./screenType.js');
require ('./preloader.js');
require ('./pannel.js');
require ('./nodule.js');


var showFutureScan = false;
window.showFutureScan = showFutureScan;

if (window.location.search.match(/futurescan/)) {
	showFutureScan = true;

}
$(document).ready(function () {

	var nodules = [];
	var $scanner = $('.scanner');

	if ($scanner.length) {


	console.log('show future', showFutureScan);

		$scanner.scanner(scannerOptions);
		scannerRepo.domains = cdnDomains;

		var scanDates = scannerOptions.scanDate.slice();
		var startupWithMip = false;

		if (scanDates.length > 2){
			scanDates.pop();
		}
		var priorDate;
		if (scanDates.length > 1) {
			var priorDate = scanDates[0];
		}


		$('.screen').each(function () {
			var screen = scannerRepo.makeScreen(
				{
					$screen: $(this)
				}
			);

			var scanDate = scanDates.pop();

			if (showFutureScan) {
				scanDates.push(scannerOptions.futureScanDate);
			}

			if (startupWithMip) {
				screen.sliceThickness = 10;
			} else {
				screen.sliceThickness = 1;
			}

			if (scanDates.length == 0) {
				// only current available
				scanDates.push(scanDate);
				startupWithMip = true;
			}
			screen.scanDate = scanDate;
			$scanner.scanner('addScreen', screen);
			console.log('scanner nodelayer nodule: ', nodule);
			console.log('scanner nodelayer nodulesettings: ', noduleSettings);

			var nodeLayer = $.extend({}, nodule, noduleSettings);
			if (priorDate  == scanDate) {
				nodeLayer.preselectNodules = true;
			} else {
				nodeLayer.preselectNodules = false;
			}

			nodeLayer.init($(this), screen);
			nodules.push(nodeLayer);

			$scanner.on('setSegmentationMode', function (nodeLayer){
				// console.log('setup segmode listener');
				return function(e, props) {

					// console.log('set segmentationMode');
					// console.log(props.mode);

					nodeLayer.setSegmentationMode(props.mode);

					screen.gotoSlice(screen, screen.sliceIndex, false, true);
					};
			}(nodeLayer, screen));

			screen.init();

		});
		nodulePannel.init();
		findingsPanel.init();
		$('.scanner .button-screen-split').trigger('click');

		$scanner.scanner('setScrollDifference');

	}

	$('.close-scanner').on('click', function () {

		window.close();
	});



	// $('.master-container .tabs li').on('click', function (e) {
	$('.findings-container .select-pannel-button').on('click', function (e) {

		e.preventDefault();

		//is pannel open?
		if($('.scanner').hasClass('selectMode')) {
			if($(this).hasClass('selected')) {
				//close the pannel.
				var height = $('.findings-container').outerHeight();
				height = height - 21;
				$('.findings-container').css('bottom', '-' + height + 'px');


				$('.scanner').removeClass('selectMode');
				$('.scroll-lock').css('bottom','26px');


			} else {
				//Switch to other tab.
				var tab = $(this).attr('id').substring(4);
				$('.select-pannel-button').removeClass('selected');
				$(this).addClass('selected');

				var $container = $(this).parents('.findings-container');

				$container.removeClass('tab-selected-nodules').removeClass('tab-selected-other-findings');
				$container.addClass('tab-selected-' + tab);
			}
		} else {
			//Pannel is still closed, open pannel.
			$('.scanner').addClass('selectMode');
			$('.findings-container').css('bottom', 0);
			var heightContainer = $('.findings-container').height();
			$('.scroll-lock').css('bottom', (heightContainer + 30) + 'px');

			//Set clicked tab selected.
			var tab = $(this).attr('id').substring(4);
			$('.select-pannel-button').removeClass('selected');
			$(this).addClass('selected');

			var $container = $(this).parents('.findings-container');

			$container.removeClass('tab-selected-nodules').removeClass('tab-selected-other-findings');
			$container.addClass('tab-selected-' + tab);

		}




	});

	//Decode URL
	var type = decodeURI(window.location.hash.substr(2));
	var urlInformation = type.split("#!");

	if(urlInformation.length > 1) {
		$('.scanner-header h2').text(urlInformation[0]);
		scannerRepo.methods.showSneakPeekButton(urlInformation[1]);
	} else {
		$('.scanner-header h2').text(type);
	}



});


var scannerRepo = {
		preloaded: {
			lung: [],
			bone: [],
			softtissue: []
		},
		brightnessFilter: {
			execute: function ($slide) {
				var brightness = $("#brightness").slider("value");
				var contrast = $("#contrast").slider("value");
				$slide.css("filter", 'brightness(' + brightness + '%) contrast(' + contrast + '%)');
			}
		},

		imageExists: function (image_url, successCallback, failedCallback) {


			$.get(image_url).done(successCallback).fail(failedCallback);
		},
		loadCanvasLayer: function (canvas, context, path, lighten, preloadedImage) {

			// console.log('load canvas layer', path, lighten);

			if (preloadedImage) {
				context.globalCompositeOperation = (lighten) ? "lighten" : 'source-over';
				context.drawImage(preloadedImage, 0, 0, context.canvas.width, context.canvas.height);
				return;
			}

			var image = new Image();

			image.onload = function () {
				var localLighten = lighten;
				return function () {
					context.globalCompositeOperation = (localLighten) ? "lighten" : 'source-over';
					context.drawImage(image, 0, 0, context.canvas.width, context.canvas.height);
				}
			}();
			image.src = path;
		},
		loadSlices: function (screen, path, readyCallback) {

			if (screen.loadingSlices) {
				return;
			}
			//console.log('load slices from :'+ scannerRepo.imageDirUrl+path);
			screen.showLoading(true);
			var done = function (screen, path, readyCallback) {

				return function (json) {
					screen.slices = json;
					screen.slicePath = path;
					readyCallback(screen);
					screen.showLoading(false);
				};

			};

			// console.log(scannerRepo.imageDirUrl+path);
			$.get(scannerRepo.imageDirUrl + path).done(done(screen, path, readyCallback)).fail(function (data) {
				console.log(fail);
				console.log(data);
			});

		},
		addPaddingZeros: function (number, digits) {
			var padded = number + "";
			while (padded.length < digits) {
				padded = "0" + padded;
			}
			return padded;
		},
		setupSlider: function (screen) {

			var slideHandler = function (screen) {
				return function (e) {
					// console.log("orientation: ", screen.orientation);
					var sliceIndex = -1 * $(e.target).slider("value");
					screen.gotoSlice(screen, sliceIndex);
				}
			};

			screen.$screen.find('.slider').slider({
				orientation: "vertical",
				range: "min",
				min: -screen.slices.length,
				max: 0,
				value: 0,
				slide: slideHandler(screen),
				change: slideHandler(screen)
			});

		},

		zzsetupCanvas: function (screen, callback, startSlice) {

// @todo nu lijkt er 2 keer geladen te worden dat zou niet moeten
			if (false && screen.canvasInitiated) {
				return;
			}
			screen.canvasInitiated = true;

			if (!startSlice) {
				startSlice = 0;
			}
			var $canvas = screen.$screen.find('canvas.slice');

			var getImageLoadedCallback = function (screen, $canvas, callback) {
				return function () {

					// console.log('place image', image);
					var height = image.height;
					var width = image.width;
					var ratio = width / height;
					// console.log('ratio', ratio);
					var parentWidth = $canvas.parent().width();
					var parentHeight = $canvas.parent().height();

					var context = $canvas[0].getContext('2d');

					if (context.canvas.width != width) {
						context.canvas.width = width;
						context.canvas.height = height;
					}

					var displayHeight = parentHeight;
					var displayWidth = parentWidth;

					if ((parentHeight * ratio) > parentWidth) {
						displayHeight = parentWidth / ratio;
					} else {
						displayWidth = parentHeight * ratio;
					}

					$canvas.width(displayWidth);
					$canvas.height(displayHeight);

					screen.$screen.trigger('resize');


					callback(screen);
				}
			};

			var image = new Image();
			image.onload = getImageLoadedCallback(screen, $canvas, callback);
			image.src = screen.getSliceUrl(screen.caseId, startSlice, screen.density, screen.orientation, screen.scanDate, screen.sliceThickness > 0);
		},

		setupCanvas: function (screen, callback, sliceNr) {

			//console.log(screen);
			if (!sliceNr){
				sliceNr = 0;
			}

			var image = new Image();
			var $canvas = screen.$screen.find('canvas.slice');
			// console.log("Canvas: ", $canvas);

			image.onload = function () {
				var height = image.height;
				var width = image.width;
				var ratio = width / height;

				var $screen = $canvas.parents('.screen');
				var parentWidth = $screen.width();
				var parentHeight = $screen.height();

				// console.log("canvas 0: ", $canvas[0]);

				var context = $canvas[0].getContext('2d');

				if (context.canvas.width != width) {
					context.canvas.width = width;
					context.canvas.height = height;
				}

				var displayHeight = parentHeight;
				var displayWidth = parentWidth;

				if ((parentHeight * ratio) > parentWidth) {
					displayHeight = parentWidth / ratio;
				} else {
					displayWidth = parentHeight * ratio;
				}

				$screen.find('canvas').width(displayWidth).height(displayHeight);
				screen.$screen.trigger('resize');
				callback(screen);
			};

			image.src = screen.getSliceUrl(screen.caseId, sliceNr, screen.density, screen.orientation, screen.scanDate, screen.sliceThickness > 0);
			//console.log(image.src);

		},
		nodeType: {},
		findingType: {},
		//scanUrlMask: '/images/scans/case-{caseId}/{density}/scan-{sliceIndex}.png',
		//scanUrlMask: '/images/TEST020/{density}/1.3.12.2.1107.5.1.4.54583.3000000801120745516560000{sliceIndex}_conv.jpg',

		imageDirUrl: '/get-file-list?dir=',
		//slicePathMask: '/images/scans/case-{caseId}/{density}',
		//slicePathMask: '/images/TEST020/{density}',
		scanUrlMask: '/images/scans/{caseId}/{scanDate}/{orientation}/{density}/{sliceIndex}.jpg',
		slicePathMask: '/images/scans/{caseId}/{scanDate}/{orientation}/{density}',
		makeNode: function (options) {
			return $.extend({}, scannerRepo.nodeType, options);
		},
		makefinding: function (options) {
			return $.extend({}, scannerRepo.findingType, options);
		},
		makeScreen: function (options) {

			return $.extend({}, screenType, options);
		},
		methods: {
			settings: {},
			init: function (options) {
				var defaults = {
					keyboardState: {
						controlDown: false,
					},
					scanUrlMask: '/images/scans/{caseId}/{scanDate}/{density}/{orientation}-{sliceIndex}.jpg',
					screens: [],
					selectedScreen: 0,
					syncScrollDifference: 0,
					nodes: [],
					findings: [],
					buttonSelectMode: '.button-select-mode',
					buttonScreenSplit: '.button-screen-split',
					buttonThickSlices: '.button-thick-slices',
					buttonBrightness: '.button-brightness',
					buttonSegmentation: '.button-segmentation',
					buttonSyncScroll: '.button-sync-scroll',
					buttonOrientation: '.button-orientation',
					buttonScanDate: '.button-scan-date',
					density: {
						bone: '.button-select-bone',
						lung: '.button-select-lung',
						softtissue: '.button-select-soft-tissue'
					},
					orientation: {
						frontal: '.select-frontal',
						transversal: '.select-transversal',
						sagital: '.select-sagital'
					},
					scanDate: {},
					latestScanDate: ''
				};

				var scanner = scannerRepo.methods;
				scanner.settings = $.extend({}, defaults, options);
				$(this).data('scanner', scanner);
				$('.mode-button').on('click', $(this).scanner('getToolClickHandler'));
				$('.sneak-peek-button').on('click', $(this).scanner('toggleSneakPeek'));


				// setup scanner with current scan dates
				var $scanControl = $('.scan-date-control');
				$scanControl.html('');
				var formatedScanDates = {};


				scanner.settings.latestScanDate = '';
				scanner.settings.scanDate.forEach(function (value, index) {
					var splitDate = value.match(/(\d{4})(\d{2})(\d{2})/);
					scanner.settings.latestScanDate = splitDate[0];
					formatedScanDates[value] = '.select-date-' + splitDate[0];
					$scanControl.append('<li><a href="#" class="select-date select-date-' + splitDate[0] + '" date-select-date="' + splitDate[0] + '">' + splitDate[3] + '-' + splitDate[2] + '- 0000' + '</a></li>');
				});

				if (showFutureScan) {

					var splitDate = scanner.settings.futureScanDate.match(/(\d{4})(\d{2})(\d{2})/);
					formatedScanDates[splitDate[0]] = '.select-date-' + splitDate[0];
					$scanControl.append('<li><a href="#" class="select-date select-date-' + splitDate[0] + '" date-select-date="' + splitDate[0] + '">' + splitDate[3] + '-' + splitDate[2] + '- 0000' + '</a></li>');

				}


				scanner.settings.scanDate = formatedScanDates;
				screenType.scanDate = scanner.settings.latestScanDate;


				var $this = $(this);
				$this.scanner('setupButtonSet', scanner.settings.density, 'selectDensity');
				$this.scanner('setupButtonSet', scanner.settings.orientation, 'selectOrientation');
				$this.scanner('setupButtonSet', scanner.settings.scanDate, 'selectScanDate');
				$(scanner.settings.buttonThickSlices).on('click', $this.scanner('selectThickSlices'));
				$this.scanner('setupScreenTitles',scanner.settings);



				$('.scanner').mouseKeyListeningHandler({
					scanner: scanner,
				});

				function handleImageControl() {
					scannerRepo.brightnessFilter.execute($('.slice'))
				}


				$("#brightness, #contrast").slider({
					orientation: "horizontal",
					range: "min",
					max: 200,
					value: 100,
					slide: handleImageControl,
					change: handleImageControl
				});


				$(scanner.settings.buttonSyncScroll).on('click', function ($this, className) {
					return function (e) {
						e.preventDefault();
						$this.toggleClass('syncScrollMode');
						scanner.settings.syncScrollDifference = scanner.settings.screens[1].sliceIndex - scanner.settings.screens[0].sliceIndex;
					}
				}($(this), scanner.settings.buttonSyncScroll));

			},

			showSneakPeekButton: function(urlInformation) {
				$('.sneak-peek-button').css('display','inline-block');

				if(urlInformation == 'snkpk0') {

					var sneakPeekExplanation = new Anno([
						{
							target : '.sneak-peek-button',
							content: 'Click this button to get a sneak peek of the nodules you haven\'t found yet.',
							buttons: {
								text:'I get it!',
							},
							onHide: function(anno, $target, $annoElem, returnFromOnShow) {

								opener.caseHandeler.setSneakPeakOptionExplained();
							}
						}
					]);

					sneakPeekExplanation.show();

				}
			},

			setupScreenTitles: function(settings) {
				var self = this;
				var $this = $(this);
				var $scanner = $('.scanner');

				$scanner.on('set-scan-date', function(event) {

					var screen = event.screen;
					var $title;
					if (screen.screenIndex == 0) {
						$title = $scanner.find('.toolbar-column.column-left .title-screen');
					} else {
						$title = $scanner.find('.toolbar-column.column-right .title-screen');
					}
					var title = 'Prior';
					if (settings.latestScanDate == screen.scanDate) {
						var title = 'Current';
					}

					if (settings.futureScanDate == screen.scanDate){
						var title = 'Follow up';
					}



					$this.scanner('setScrollDifference');

					var dateParts =  screen.scanDate.match(/(.{4})(.{2})(.{2})/);

					$title.find('h3').html(title);
					$title.find('.scandate-dropdown').html(dateParts[3] + '-' + dateParts[2] + '-0000');
					$title.find('datepicker li').removeClass('selected');
					$title.find('datepicker [date-select-date='+screen.scanDate +']').parent().addClass('selected');

				});

			$('.toolbar-column .title-screen').on('click', function(event){
				$(this).find('datepicker').toggleClass('active');

			}).css('cursor','pointer');


			},
			setScrollDifference: function () {
				var $this = $(this);
				var scanner = $this.data('scanner');
				var scanDate1 = '';
				var scanDate2 = '';
				var orientation1 = '';
				var orientation2 = '';


				// console.log("setScrollDifference!");

				if($(this).find(scanner.settings.buttonScreenSplit).hasClass('mode-selected') && $this.hasClass('syncScrollMode')) {
					//Screen is split and syncscroll is on.
					// console.log("screen is split and in sync mode");
					scanner.settings.screens.forEach(function (value, index) {
						if(index == 0) {
							scanDate1 = value.scanDate;
							orientation1 = value.orientation;
							console.log('scandate1: ' + scanDate1);
							console.log('orientation1: ' + orientation1);

						} else {
							scanDate2 = value.scanDate;
							orientation2 = value.orientation;
							console.log('scandate2: ' + scanDate2);
							console.log('orientation2: ' + orientation2);

						}
					});
				}

				if((orientation1 === 'transversal' && orientation2 === 'transversal') && scanDate1 != scanDate2) {
					var scrollDif = 0;
					 // console.log('caseData.objectSettings: ', caseData.objectSettings[0])
					 // console.log('scandate not same ', caseData.scanDate[0])
					if(caseData.objectSettings[caseData.scanDate[0]].scrollDifference) {
						scrollDif = caseData.objectSettings[caseData.scanDate[0]].scrollDifference;
					} else {
						scrollDif = 0;
					}

					//is prior left on the left screen?
					if(scanDate1 < scanDate2) {
						scrollDif = scrollDif*-1;
					}

					scanner.settings.syncScrollDifference = scrollDif;
				} else {
					scanner.settings.syncScrollDifference = 0;
				}

			},
			setupButtonSet: function (buttonSet, clickHandlerMethod) {
				var $this = $(this);
				$(buttonSet).each(function (dummy, keyValuePair) {

					$.each(keyValuePair, function (code, classSelector) {
						$(classSelector).on('click', $this.scanner(clickHandlerMethod, code));
					});
				});
			},
			changeSelectedButtonInSet: function (buttonSet, propertyName) {
				var $this = $(this);

				var scanner = $this.data('scanner');
				var propertyValueForActiveScreen = scanner.settings.screens[scanner.settings.selectedScreen][propertyName];
				$(buttonSet).each(function (dummy, keyValuePair) {
					$.each(keyValuePair, function (code, classSelector) {

						$(classSelector).parent('li').removeClass('selected');
					});
				});
				var activeButtonClassSelector = scanner.settings[propertyName][propertyValueForActiveScreen];
				$(activeButtonClassSelector).parent('li').addClass('selected');

			},
			selectDensity: function (density) {

				var $this = $(this);

				return function (e) {
					e.preventDefault();
					var scanner = $this.data('scanner');
					if (scanner.settings.screens[scanner.settings.selectedScreen]) {
						scanner.settings.screens[scanner.settings.selectedScreen].density = density;
						scanner.settings.screens[scanner.settings.selectedScreen].loadSlice(scanner.settings.screens[scanner.settings.selectedScreen], scanner.settings.screens[scanner.settings.selectedScreen].sliceIndex);
					}
					$this.scanner('setSlices', scanner);
					$this.scanner('setControlState');
				};

			},

			selectOrientation: function (orientation) {
				var $this = $(this);
				return function (e) {
					e.preventDefault();
					var scanner = $this.data('scanner');
					if (scanner.settings.screens[scanner.settings.selectedScreen]) {
						scanner.settings.screens[scanner.settings.selectedScreen].orientation = orientation;
						scanner.settings.screens[scanner.settings.selectedScreen].$screen.attr('data-orientation', orientation);
						scanner.settings.screens[scanner.settings.selectedScreen].loadSlice(scanner.settings.screens[scanner.settings.selectedScreen], scanner.settings.screens[scanner.settings.selectedScreen].sliceIndex);

						if($this.scanner('screenOrientationsDiffer', scanner)) {
							$this.scanner('turnSyncScrollModeOn', false);
						} else {
							$this.scanner('turnSyncScrollModeOn', true);
							$this.scanner('setScrollDifference');
						}
					}
					$this.scanner('setSlices', scanner);
					$this.scanner('setControlState');

				};
			},

			selectThickSlices: function (orientation) {
				var $this = $(this);
				return function (e) {
					e.preventDefault();
					var scanner = $this.data('scanner');

					$this.scanner('setSlices', scanner);

				};
			},

			selectScanDate: function (scanDate) {
				var $this = $(this);

				return function (e) {

					e.preventDefault();
					var scanner = $this.data('scanner');

					var screenIndex = scanner.settings.selectedScreen;

					var containerClassName = $(e.target).parents('.toolbar-column')[0].className;
					if (containerClassName) {
						if (containerClassName.match(/-left/)) {
							screenIndex = 0;
						} else if (containerClassName.match(/-right/)) {
							screenIndex = 1;
						}
					}


					if (scanner.settings.screens[screenIndex]) {
						scanner.settings.screens[screenIndex].scanDate = scanDate;
						scanner.settings.screens[screenIndex].loadSlice(scanner.settings.screens[screenIndex], scanner.settings.screens[screenIndex].sliceIndex);
						scanner.settings.screens[screenIndex].$screen.trigger('scanDate-changed');
					}
					$('.scanner').trigger({type:'set-scan-date', screen: scanner.settings.screens[screenIndex]});
					$this.scanner('setControlState');
				};
			},
			setSlices: function(scanner) {
				var caseId = scanner.settings.screens[scanner.settings.selectedScreen].caseId;
				var scanDate = scanner.settings.screens[scanner.settings.selectedScreen].scanDate;
				var density = scanner.settings.screens[scanner.settings.selectedScreen].density;
				var orientationCode = 'CC';
				if (scanner.settings.screens[scanner.settings.selectedScreen].orientation === 'frontal') {
					orientationCode = 'AP';
				} else if (scanner.settings.screens[scanner.settings.selectedScreen].orientation === 'sagital') {
					orientationCode = 'SD';
				}

				// add mip to orientation
				if (scanner.settings.screens[scanner.settings.selectedScreen].sliceThickness > 1) {
					orientationCode = orientationCode + '-MIP-10';
				}
				console.log('setSlices slices path: ' + scannerRepo.imageDirUrl + '/images/scans/' + caseId + '/' + scanDate + '/' + orientationCode + '/' + density);

				// gets and sets file names of selected scan set
				$.get(scannerRepo.imageDirUrl + '/images/scans/' + caseId + '/' + scanDate + '/' + orientationCode + '/' + density)
					.done(
						function (json) {
							scanner.settings.screens[scanner.settings.selectedScreen].slices = json;
						}
					)
					.fail(function (data) {
						console.log(fail);
						console.log(data);
					});
			},
			screenOrientationsDiffer: function (scanner) {

				var orientation1 = '';
				var orientation2 = '';

				scanner.settings.screens.forEach(function (value, index) {
					if(index == 0) {
						orientation1 = value.orientation;
					} else {
						orientation2 = value.orientation;
					}
				});

				if(orientation1 !== orientation2) {
					return true;
				} else {
					return false;
				}


			},

			turnSyncScrollModeOn: function (syncOn) {
				$('.scanner').toggleClass('syncScrollMode', syncOn);
			},

			setControlState: function () {
				var $this = $(this);
				var scanner = $this.data('scanner');
				$this.scanner('changeSelectedButtonInSet', scanner.settings.density, 'density');
				$this.scanner('changeSelectedButtonInSet', scanner.settings.orientation, 'orientation');
				//$this.scanner('changeSelectedButtonInSet', scanner.settings.scanDate, 'scanDate');

				var activeScreenThickSlices = scanner.settings.screens[scanner.settings.selectedScreen].sliceThickness > 1;
				$(scanner.settings.buttonThickSlices).toggleClass('mode-selected', activeScreenThickSlices);

			},

			toggleSneakPeek: function () {

				return function (e) {
					$('.sneak-peek-button').toggleClass('active');
					$('.scanner').trigger({type: 'sneakPeek', active: $('.sneak-peek-button').hasClass('active')});
				}
			},

			getToolClickHandler: function () {
				var $this = $(this);
				return function (e) {
					e.preventDefault();
					$(this).toggleClass('mode-selected');
					$this.scanner('handleModeChange', {target:$(e.currentTarget)});
				}
			},

			handleModeChange: function (options) {
				var clickedButtonClasses = options.target[0].className;

				var scanner = $(this).data('scanner');
				var inSelectMode = $(this).find(scanner.settings.buttonSelectMode).hasClass('mode-selected');
				var inSplitScreenMode = $(this).find(scanner.settings.buttonScreenSplit).hasClass('mode-selected');
				var inThickSlicesMode = $(this).find(scanner.settings.buttonThickSlices).hasClass('mode-selected');
				var inlevelsMode = $(this).find(scanner.settings.buttonBrightness).hasClass('mode-selected');
				var inSegmentationMode = $(this).find(scanner.settings.buttonSegmentation).hasClass('mode-selected');

				var inOrientationMode = $(this).find(scanner.settings.buttonOrientation).hasClass('mode-selected');
				var inSynchScrollMode = $(this).find(scanner.settings.buttonSyncScroll).hasClass('mode-selected');
				var inScanDateMode = $(this).find(scanner.settings.buttonScanDate).hasClass('mode-selected');


				// handle split screen toggleing
				if (clickedButtonClasses.match(scanner.settings.buttonScreenSplit.slice(1))) {
					if (inSplitScreenMode) {
						$(this).addClass('splitScreenMode');

						var currentSlice = scanner.settings.screens[scanner.settings.selectedScreen].sliceIndex;
						$(scanner.settings.screens).each(function (index, screen) {
							screen.sliceIndex = currentSlice;
							scannerRepo.setupCanvas(screen, function (screen, currentSlice) {
								return function () {
									screen.gotoSlice(screen, currentSlice, false, true);
								}
							}(screen, currentSlice), currentSlice);
						});

						if(scannerRepo.methods.screenOrientationsDiffer(scanner)) {
							scannerRepo.methods.turnSyncScrollModeOn(false);
						} else {
							scannerRepo.methods.turnSyncScrollModeOn(true);
						}

					} else {

						$(this).removeClass('splitScreenMode');
						$(scanner.settings.screens).each(function (index, screen) {
							scannerRepo.setupCanvas(screen, function () {
							});
						});
					}
				}


				if (inThickSlicesMode) {
					$(this).addClass('thickLayerMode');
					$(scanner.settings.screens).each(
						function (key, screen) {
							if (key == scanner.settings.selectedScreen) {
								screen.sliceThickness = 10;
							}
						}
					);
				} else {
					$(this).removeClass('thickLayerMode');
					$(scanner.settings.screens).each(
						function (key, screen) {
							if (key == scanner.settings.selectedScreen) {
								screen.sliceThickness = 1;
							}
						}
					);
				}

				$(this).trigger('setSegmentationMode', {'mode': inSegmentationMode});

				if (inlevelsMode) {
					$(this).addClass('levelsMode');

				} else {
					$(this).removeClass('levelsMode');
				}

				if (inOrientationMode) {
					$(this).addClass('orientationMode');
				} else {
					$(this).removeClass('orientationMode');
				}

				if (inScanDateMode) {
					$(this).addClass('scanDateMode');
				} else {
					$(this).removeClass('scanDateMode');
				}

				if (inSelectMode) {
					$(this).addClass('selectMode');
				} else {
					$(this).removeClass('selectMode');
				}

				scanner.info('handleModeChange');
			},

			addNode: function (node) {
				var scanner = $(this).data('scanner');
				scanner.info('add node:');
				scanner.info(node);

			}
			,
			addFinding: function (finding) {
				var scanner = $(this).data('scanner');
				scanner.info('add finding:');
				scanner.info(finding);
			},
			addScreen: function (screen) {

				var scanner = $(this).data('scanner');

				var $this = $(this);
				screen.screenIndex = scanner.settings.screens.length;
				screen.sliceFilters.push(scannerRepo.brightnessFilter);
				screen.caseId = scanner.settings.caseId;
				scanner.settings.screens.push(screen);
				scanner.info(screen);

				screen.$screen.on('click', function () {
					var localScanner = scanner;
					var myScreen = screen;
					return function (e) {
						e.preventDefault();
						localScanner.settings.selectedScreen = myScreen.screenIndex;
						$('.screen').removeClass('active');
						myScreen.$screen.addClass('active');
						$this.scanner('setControlState');

						$('.toolbar-column.column-left').toggleClass('active', myScreen.screenIndex == 0);
						$('.toolbar-column.column-right').toggleClass('active', myScreen.screenIndex != 0);

					}
				}());

				screen.loadSlice(screen, 0);
				screen.$screen.on('DOMMouseScroll mousewheel', screen.getScrollHandler(screen));
			},



			info: function (data) {
				// console.log(data)
			},
			importData: function (data) {
				data.nodes.each(function (item) {
					var node = scannerRepo.makeNode(item);
					scanner.addNode(node);

				});
				data.findings.each(function (item) {
					var finding = scannerRepo.makeFinding(item);
					scanner.addFinding(finding);
				});
			}
			,
			exportData: function () {
				return {
					nodes: scanner.settings.nodes,
					findings: scanner.settings.findings
				}
			}
		}
	}
;
window.scannerRepo = scannerRepo;



$.fn.scanner = function (method) {
	var scanner;
	if (scannerRepo.methods[method]) {
		scanner = $(this).data('scanner');
		return scanner[method].apply(this, Array.prototype.slice.call(arguments, 1));
	} else if (typeof method === 'object' || !method) {
		return scannerRepo.methods.init.apply(this, arguments);
	} else {
		$.error('Method ' + method + ' does not exist on jQuery.scanner');
	}
}

