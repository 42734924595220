var popover = {
	show:function(){
		$('.lesson-header-container').addClass('blur');
		$('main').addClass('blur');
		$('.popover').addClass('show');
	},
	hide:function(){
		$('.lesson-header-container').removeClass('blur');
		$('main').removeClass('blur');
		$('.popover').removeClass('show');
	}

};
window.popover = popover;

$(document).ready(function() {


	if($('.popup-close')){
		$('.popup-close').on('click',popover.hide);
	}

	if($('.start-lesson-button')){
		$('.start-lesson-button').on('click',popover.hide);
	}

});