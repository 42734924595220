/**
 * preloader for dicom images
 * @param options
 */
$.fn.preload = function (options) {

	/**
	 * Load a single image
	 * @param key
	 * @param value
	 * @param callback
	 */
	var loadImage = function (key, value, callback) {
		var numberOfDomains = scannerRepo.domains.length;

		var image = new Image();
		if (callback) {
			image.onload = callback;
		}

		image.src = scannerRepo.domains[key % numberOfDomains] + options.path + '/' + value;
		options.destination[key] = image;
	};



	var startKey = 0;
	if (options.startKey > 0) {
		startKey = options.startKey;
	}

	var firstResultCallback = function(){};
	if (options.firstResultCallback) {
		firstResultCallback = options.firstResultCallback;
	}
	loadImage(startKey, this[startKey], firstResultCallback);
	var arrayLength = this.length;
	for (var i = 1; i < arrayLength; i++) {
		if (startKey - i >= 0) {
			loadImage(startKey - i, this[startKey - i]);
		}
		if (startKey + i < arrayLength) {
			loadImage(startKey + i, this[startKey + i]);
		}
	}

};


