var sideNavigation = {
    open: function (){
        console.log("open navigation");
		sideNavigation.updateHeight();
        $('.side-navigation').toggleClass('open');
    },
    close: function (){
        $('.side-navigation').removeClass('open').removeClass('nav-expanded');
    },
    toggleMore: function() {
        $('.side-navigation').toggleClass('nav-expanded');
    },
    updateHeight: function () {

		$('.side-navigation').css('height', $(document).height()-$('.lesson-header-container').height());
		$('.side-navigation').css('top', $('.lesson-header-container').height());
    }
    
    
}

$(document).ready(function() {
    $('.nav-open').on('click',sideNavigation.open);
    $('.nav-close').on('click', sideNavigation.close);
    $('#show-more').on('click', sideNavigation.toggleMore);


});

