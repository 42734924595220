
var nodulePannel = {
	settings:{
		foundNoduleCount: 0,
		templates: {
			noduleLabel:null,
			noduleDetails: null
		}
	},
	init: function(){
		this.loadTemplates();

		var pannel = this;
		$('.scanner').on('nodule-found', pannel, function(event){
			console.log("inge nodule-found event");
			pannel.addNodule(event);
		});

		$(window).resize(function() {
			pannel.resizePannel();
		});

		this.setCurrentDate();
		this.hidePannel();

		this.reloadNodules();

	},

	resizePannel: function() {
		if(!$('.scanner').hasClass('selectMode')) {
			var height = $('.findings-container').outerHeight();
			height = height - 21;
			$('.findings-container').css('bottom', '-' + height + 'px');
		}
	},

	hidePannel: function() {
		var height = $('.findings-container').outerHeight();
		height = height - 21;
		$('.findings-container').css('bottom', '-' + height + 'px');
		$('.findings-container').css('opacity', 1);

	},

	setCurrentDate: function() {
		var date = currentDate.toString();
		$('.findings-container').find('.scan-date-pannel').html('(' + date.substring(6,8) + '-' + date.substring(4,6) + '-0000)');
	},

	reloadNodules: function() {

		var nodulesFromProtocol = opener.caseHandeler.getNoduleInfo();

		console.log('nodulesFromProtocol: ', nodulesFromProtocol);

		if (!$.isEmptyObject(nodulesFromProtocol)) {

				// console.log(nodulesFromProtocol);
				// console.log('set nodules');

			var perfomAfterMeshesLoaded = function() {
				console.log('perfomAfterMeshesLoaded');
				nodulesFromProtocol.forEach(function (nodule, index) {
					var rebuildNoduleEvent = {
						type: nodule.createEvent.type,
						noduleId: nodule.createEvent.noduleId,
						boundingBox: nodule.createEvent.boundingBox,
						zSliceTop: nodule.createEvent.zSliceTop,
						zSliceBottom: nodule.createEvent.zSliceBottom,
						sliceIndex: nodule.createEvent.sliceIndex,
						scanDate: nodule.createEvent.scanDate,
						volume_correct: nodule.createEvent.volume_correct,
						vdt_or_other_changes: nodule.createEvent.vdt_or_other_changes
					};
					$('.scanner').trigger(rebuildNoduleEvent);

					// vul pannel
					var $noduleInfoPanel = $('.details-nodule-id-' + nodule.createEvent.noduleId + ' > div').eq(0);
					$noduleInfoPanel.find('select[name=select-category]').val(nodule.category);
					$noduleInfoPanel.find('select[name=select-location]').val(nodule.location);
					// $noduleInfoPanel.find('select[name=select-density]').val(nodule.density); // no longer used?
					$noduleInfoPanel.find('select[name=select-shape]').val(nodule.shape);
					// $noduleInfoPanel.find('select[name=select-edge]').val(nodule.edge);  // no longer used?
					$noduleInfoPanel.find('select[name=select-attached]').val(nodule.attached);
					$noduleInfoPanel.find('select[name=select-type]').val(nodule.type);

					$noduleInfoPanel.find('select[name=select-segment]').val(nodule.segment);
					$noduleInfoPanel.find('select[name=select-calcification]').val(nodule.calcification);
					$noduleInfoPanel.find('select[name=select-margin]').val(nodule.margin);
					$noduleInfoPanel.find('select[name=select-fat]').val(nodule.fat);

				});
				$('.findings-container').trigger('data-change');
			};
			$('.scanner').on('meshesLoaded',perfomAfterMeshesLoaded);




				//$('.select-list.content-nodules li span').removeClass('is-visible').addClass('is-invisible');

		}
		$('.findings-container').on('data-change', this.getHandleChange(this));

		

	},

	loadTemplates: function(){
		var sourceLabel   = document.getElementById("nodule-label-template").innerHTML;
		this.settings.templates.noduleLabel = Handlebars.compile(sourceLabel);
		var sourceDetails   = document.getElementById("nodule-details-template").innerHTML;
		this.settings.templates.noduleDetails = Handlebars.compile(sourceDetails);

	},
	addNodule: function(event){

		// @todo: in het event moet de nodule kleur meekomen zodat de nodule met de juiste kleur in het panel komt.

		console.log('Add nodule event: ', event);

		//Only add nodule if it is a current nodule (for now)
		if(event.scanDate == currentDate) {

			var noduleId = event.noduleId;
			console.log('add nodule event',event);

			var color = event.color;
			//var noduleNumber = noduleId + 1;
			var diameterX = Math.round(10 * Math.abs(event.boundingBox.max.x - event.boundingBox.min.x)) / 10;
			var diameterY = Math.round(10 * Math.abs(event.boundingBox.max.y - event.boundingBox.min.y)) / 10;
			var diameterZ = Math.round(10 * Math.abs(event.boundingBox.max.z - event.boundingBox.min.z)) / 10;

			var year = event.scanDate.substr(0, 4);
			var month = event.scanDate.substr(4, 2);
			var day = event.scanDate.substr(6, 2);

			var detectionTime = Math.round((new Date()).getTime()/1000);

			var hasNodule = true;

			if ($('#nodule-label-template').siblings('.nodule-id-' + noduleId).length == 0) {
				this.settings.foundNoduleCount++;
				hasNodule = false;
			}
			var noduleVo = $.extend({}, event, {
				noduleIndex: this.settings.foundNoduleCount,
				noduleId: noduleId,
				diameter: {x: diameterX, y: diameterY, z: diameterZ},
				scanDateFormatted: day + '-' + month + '-' + year,
				detectionTime: detectionTime,
			});

			console.log('casedata: ', caseData);
			console.log('noduleSettings: ', noduleSettings);




			if (!hasNodule && currentDate == event.scanDate) {
				var labelHtml = this.settings.templates.noduleLabel(noduleVo);
				var $noduleLabel = $(labelHtml).appendTo($('#nodule-label-template').parent());
				$noduleLabel.on('click',this.getSelectNoduleHandler(noduleId));
				$noduleLabel.find('span.eye').on('click',this.getToggleVisibilityHandler(noduleId));
				$noduleLabel.find('span.eye').hover(
						function() {
							$('span.explain-visibility').addClass('active');
						}, function() {
							$('span.explain-visibility').removeClass('active');
						}
				);
				console.log('$noduleLabel',$noduleLabel, color)
				$noduleLabel.eq(0).attr('style','background-image:url(/images/nodule-dot/' + color +'.svg);');
				$noduleLabel.find('span.remove-nodule').on('click',this.getRemoveNoduleHandler(noduleId));
			}
			var hasNoduleForScanDate = $('.content-nodules .details-nodule-id-' + noduleId + ' .details-nodule-scanDate-' + event.scanDate).length > 0;


			console.log('hasNoduleForScanDate', hasNoduleForScanDate);
			if (!hasNoduleForScanDate) {
				var detailsHtml = this.settings.templates.noduleDetails(noduleVo);
				var $detail;
				var $scanDateTab;
				if (!hasNodule) {
					// console.log('addHtml');
					$detail =  $(detailsHtml).appendTo($('#nodule-details-template').parent());
					$scanDateTab = $detail.find('.tabs li');
				} else  {
					var detailTab  = $(detailsHtml).find('.tabs li')[0].outerHTML;
					var detailScanResultHtml = $(detailsHtml).find('.details-nodule-scanDate-'+ event.scanDate)[0].outerHTML;
					$detail =  $(detailScanResultHtml).prependTo($('.content-nodules .details-nodule-id-' + noduleId));
					$scanDateTab = $(detailTab).appendTo($('.content-nodules .details-nodule-id-' + noduleId + ' .tabs'));
				}

				$scanDateTab.on('click', this.getScanDateClickHandler(noduleId));
				$scanDateTab.trigger('click');


				$('.details-nodule-id-' + noduleId).data('createEvent', event);


				$detail.find('select').on('change', function(){$('.findings-container').trigger('data-change');});


				$('li.nodule-id-' + noduleId).trigger('click');
				$('.findings-container').trigger('data-change');



			}

		}



	},
	getScanDateClickHandler: function(noduleId){
		return function(event){
			event.preventDefault();

			var scanDate = $(this)[0].className.split('-').pop().replace(/\sselected/,'');

			$(this).siblings().removeClass('selected');
			$(this).addClass('selected');
			// console.log($(this));
			$('.content-nodules .details-nodule-id-' + noduleId + ' > div').removeClass('selected');
			$('.content-nodules .details-nodule-id-' + noduleId + ' .details-nodule-scanDate-' + scanDate).addClass('selected');

		}
	},
	getSelectNoduleHandler: function(noduleId) {
		return function(event) {
			event.preventDefault();
			$('.content-nodules > div.activeNodule').removeClass('activeNodule');
			$('.content-nodules .details-nodule-id-' + noduleId).addClass('activeNodule');
			$(this).siblings().removeClass('activeNodule');
			$(this).addClass('activeNodule');
		}
	},
	getToggleVisibilityHandler: function(noduleId) {
		return function(event) {
			event.preventDefault();

			if ($(this).hasClass('is-visible')){
				$(this).removeClass('is-visible').addClass('is-invisible');
				$('.scanner').trigger({type:'change-nodule-opacity', noduleId: noduleId, opacity:0});
			} else {
				$(this).addClass('is-visible').removeClass('is-invisible');
				$('.scanner').trigger({type:'change-nodule-opacity', noduleId: noduleId, opacity:1});
			}
		}
	},
	getRemoveNoduleHandler: function(noduleId){
		return function(event) {
			event.preventDefault();

			console.log("in getRemoveNoduleHandler");

			var classnames = event.currentTarget.className.split(' ');
			var targetClass;
			classnames.forEach(function(value) {
				if (value.match(/^remove-nodule-scanDate/)){
					targetClass = value.replace(/remove/, "details");
				}
			});
			var scanDate = targetClass.split('-').pop();

			var $noduleContainer = $('.details-nodule-id-' + noduleId);
			$noduleContainer.find(' .' + targetClass).remove();
			$noduleContainer.find('.tab-date-' + scanDate).remove;

			if ($noduleContainer.find('div').length == 0){
				$noduleContainer.remove();
				$('.master-container .nodule-id-' + noduleId).remove();


				// remove nodule from scans
				$('.scanner').trigger({type: 'nodule-removed', noduleId: noduleId, scanDate: scanDate});
			}

			// @todo scanDate secifieke nodule verbergen.


			// $('.findings-container').trigger('data-change');
			opener.caseHandeler.noduleRemoved(noduleId);

		};
	},
	getHandleChange: function(self){

		return function(event) {

			console.log('getHandleChange');
			var nodules = self.collectedNoduleData();
			console.log(nodules);
			console.log('call opener');
			opener.caseHandeler.receiveNoduleInfo(nodules);
			console.log('na de opener');
		}
	},
	collectedNoduleData: function(){
		var nodules = [];
		$('.master-container .content-nodules .nodules li').each(
		 	function() {
		 		var noduleId = $(this)[0].className.split(' ')[0].substr(10);
				nodules.push(noduleId);

		 	}
		);

		var data = [];
		// console.log(nodules);
		for (var i = 0; i < nodules.length; i++) {
			var $noduleInfoPanel = $('.details-nodule-id-' + nodules[i] + ' > div').eq(0);

			console.log('createevent data: ', $noduleInfoPanel.parent().data('createEvent'));

			var noduleInfo = {
				noduleId: nodules[i],
				slice: $noduleInfoPanel.find('.node-details').attr('data-slice'),
				date: $noduleInfoPanel.find('.node-details').attr('data-detection-time'),
				category: $noduleInfoPanel.find('select[name=select-category]').val(),
				location: $noduleInfoPanel.find('select[name=select-location]').val(),
				segment: $noduleInfoPanel.find('select[name=select-segment]').val(),
				calcification: $noduleInfoPanel.find('select[name=select-calcification]').val(),
				shape: $noduleInfoPanel.find('select[name=select-shape]').val(),
				margin: $noduleInfoPanel.find('select[name=select-margin]').val(),
				attached: $noduleInfoPanel.find('select[name=select-attached]').val(),
				fat: $noduleInfoPanel.find('select[name=select-fat]').val(),
				type: $noduleInfoPanel.find('select[name=select-type]').val(),
				createEvent: $noduleInfoPanel.parent().data('createEvent')
			};

			data.push( noduleInfo );
		}
		return data;
	}
};
window.nodulePannel = nodulePannel;
var findingsPanel = {

	settings:{
		templates:
			{
			findingsLabel:null,
			findingsDetails: null
		},
		findingIndex: 0
	},

	init: function(){
		this.loadTemplates();
		$('.add-other-finding').on('click', this.getAddClickHandler(this) );
		this.reloadOtherFindings();
		$('.findings-container').on('data-other-findings-change', this.getHandleChange(this));

		$('.micronodules-warning').show();
		setTimeout(function() {
			$('.micronodules-warning').hide();
		}, 10000);

	},

	loadTemplates: function(){
		var sourceLabel   = document.getElementById("findings-label-template").innerHTML;
		this.settings.templates.findingsLabel = Handlebars.compile(sourceLabel);
		var sourceDetails   = document.getElementById("findings-details-template").innerHTML;
		this.settings.templates.findingsDetails = Handlebars.compile(sourceDetails);

	},

	reloadOtherFindings: function() {

		var otherFindingsFromProtocol = opener.caseHandeler.getOtherFindingsInfo();
		if (!$.isEmptyObject(otherFindingsFromProtocol)) {

			// console.log(otherFindingsFromProtocol);
			// console.log('set other findings');

			var self = this;
			otherFindingsFromProtocol.forEach(function (finding, index) {
// console.log(finding);
				self.popuplateFinding({findingIndex: finding.findingId, orientation: finding.orientation, sliceNr: finding.sliceNr, findingType: finding.type, notes: finding.notes}, self.settings.templates);
				// console.log(self.settings.findingIndex,' < ', parseInt(finding.findingId));

				if (self.settings.findingIndex < parseInt(finding.findingId)) {
					self.settings.findingIndex = parseInt(finding.findingId);
				}

			});
		}
	},

	getHandleChange: function(self){

		return function(event) {
			console.log('data change handler');
			var findings = self.collecteOtherFindingsData();

			console.log(opener.caseHandeler);
		 opener.caseHandeler.receiveOtherFindingsInfo(findings);
		}
	},

	getAddClickHandler: function(self) {
		return function (event) {
			event.preventDefault();

			self.settings.findingIndex++;
			var sliceNr = $('.screen.active .node-container canvas').attr('data-slicenr');

			var orientation = $('.select-orientation .selected a')[0].className;
			var slideOrientation;
			if (orientation == 'select-transversal'){
				slideOrientation = 'cranial-caudal';
			} else if (orientation == 'select-frontal') {
				slideOrientation = 'anterior-posterior';
			} if (orientation == 'select-sagital') {
				slideOrientation = 'left-right';
			}

			self.popuplateFinding({findingIndex: self.settings.findingIndex, orientation:slideOrientation, sliceNr: sliceNr, findingType:'', notes:''}, self.settings.templates);

		}
	},

	popuplateFinding: function(data, templates){

		var labelHtml = templates.findingsLabel({findingIndex: data.findingIndex});
		var $label = $(labelHtml).appendTo($('#findings-label-template').parent());
		$label.on('click', findingsPanel.getSelectFindingHandler(data.findingIndex));
		$label.find('.remove-finding').on('click', findingsPanel.getRemoveFindingHandler(data.findingIndex));
		var detailsHtml = templates.findingsDetails({findingIndex: data.findingIndex, orientation: data.orientation, sliceNr: data.sliceNr, notes: data.notes});

		var $detail =  $(detailsHtml).appendTo($('#findings-details-template').parent());
		$detail.find('a.remove-finding').on('click', findingsPanel.getRemoveFindingHandler(data.findingIndex));
		$detail.find('select[name=select-type]').val(data.findingType);
		$detail.find('select, textarea').on('change keyup paste', function(){
			$detail.find('.save-finding').addClass('active');
		});

		$detail.find('.save-finding').on('click', function() {

			var selectVal = $('.activeFinding').find('select[name=select-type]').val();

			if(selectVal === '') {
				window.alert("Please select a findingstype");
			} else {
				$('.findings-container').trigger('data-other-findings-change');
				$detail.find('.save-finding').removeClass('active');
			}

		});

		$label.trigger('click');
	},

	getRemoveFindingHandler: function(findingIndex){
		return function(event){
			event.preventDefault();
			$('li.findings-id-' + findingIndex + ', .content-other-findings .findings-detail-' + findingIndex).remove();
			$('.other-findings > li').eq(0).trigger('click');
			$('.findings-container').trigger('data-other-findings-change');
		}

	},

	getSelectFindingHandler: function(findingIndex) {
		return function(event) {
			// console.log('SelectFindingHandler handler:' ,findingIndex );
			$('.other-findings li.activeFinding, .content-other-findings > div.activeFinding').removeClass('activeFinding');
			// console.log($('.other-findings li'));
			$('li.findings-id-' + findingIndex + ', .content-other-findings .findings-detail-' + findingIndex).addClass('activeFinding');
		};
	},

	collecteOtherFindingsData: function(){
		var findings = [];
		$('.master-container .content-other-findings .other-findings li').each(
			function() {
				var findingId = $(this)[0].className.split(' ')[0].substr(12);
				findings.push(findingId);
			}
		);

		var data = [];
		// console.log(findings);
		for (var i = 0; i < findings.length; i++) {
			var $otherFindingsInfoPanel = $('.findings-detail-' + findings[i]).eq(0);
			var otherFindingsInfo = {
				findingId: findings[i],
				slice: $otherFindingsInfoPanel.find('.finding-details').attr('data-slice'),
				sliceNr: $otherFindingsInfoPanel.find('.finding-details').attr('data-slicenr'),
				orientation: $otherFindingsInfoPanel.find('.finding-details').attr('data-orientation'),
				type: $otherFindingsInfoPanel.find('select[name=select-type]').val(),
				notes: $otherFindingsInfoPanel.find('textarea[name=additional]').val()
			};
			data.push( otherFindingsInfo );
		}
		return data;
	}
};

window.findingsPanel = findingsPanel;