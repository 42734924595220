var collapseBoxClickHandler = function(event){
	event.preventDefault();

	var collapseDiv = $(this).find('.collapse');
	var link = $(this).find('.toggle-collapse');
	var wrapper = $(this).find('.measuringWrapper');

	if (collapseDiv.height()) {
		collapseDiv.height(0);
		link.text('Show your given answer');
		link.removeClass('open');
	} else {
		collapseDiv.height(wrapper.height())// = wrapper.clientHeight + "px";
		link.text('Hide your given answer');
		link.addClass('open');
	}
};

window.collapseBoxClickHandler = collapseBoxClickHandler;
$(document).ready(function() {
	$('.collapse-box').on('click', window.collapseBoxClickHandler);
	
});