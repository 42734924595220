var checklist = {
	toggle: function (e) {
		e.preventDefault();
		if ($('.checklist-title').length == 0) {
			var url = $('.checklist-open').data('checklist');
			//var url = '/case/checklist/1/1';
			$.ajax({
				url: url,
				method: 'GET',
				xhrFields: {
					withCredentials: true
				},
				dataType: 'html'
			}).done(
				function (html) {
					$('body').append(html);
					$('.navigation-checklist .nav-close').on('click', function(){
						$('.navigation-checklist').removeClass('open');
					});
					$('.side-navigation .nav-close').trigger('click');
					$('.checklist-open').trigger('click');
				}
			);
		} else {
			$('.side-navigation .nav-close').trigger('click');
			$('.navigation-checklist').toggleClass('open');
		}
		
	}
}

$(document).ready(function () {
	$('.checklist-open').on('click', checklist.toggle);
	$('.nav-open').on('click', function() {
		$('.navigation-checklist .nav-close').trigger('click');
	});
});
