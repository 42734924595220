$(document).ready(function() {
	$('.change-nodule').click(ChangeImage);

});

var images = [
	"/images/case-nodule-2.png"
];
window.images = images;

var currentImage = -1;

function ChangeImage(event)
{

	currentImage++;

	if (currentImage > images.length - 1) {
		//Go to case page 3
		//window.location.href = '/cases/case-1/3';
	} else {
		$(".case-nodule").attr("src", images[currentImage]);
		event.preventDefault();
	}



}