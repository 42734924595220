require('./bootstrap');

var context = require.context("./custom", true, /\.js$/);
context.keys().forEach(function (key) {
	context(key);
});

context = require.context("./vendor", true, /\.js$/);
context.keys().forEach(function (key) {
	context(key);
});


context = require.context("../../structures", true, /\.js$/);
context.keys().forEach(function (key) {
	context(key);
});

context = require.context("../../../vendor/alledaagsdevelopment/atomic-elements/src", true, /\.js$/);
context.keys().forEach(function (key) {
	context(key);
});


$(document).ready(function() {

	console.log('app document be ready');
	// allow for css transitions on document ready
	$('.delay-progress').removeClass('delay-progress');
});

$.fn.extend({
	animateCss: function(animationName, callback) {
		var animationEnd = (function(el) {
			var animations = {
				animation: 'animationend',
				OAnimation: 'oAnimationEnd',
				MozAnimation: 'mozAnimationEnd',
				WebkitAnimation: 'webkitAnimationEnd',
			};

			for (var t in animations) {
				if (el.style[t] !== undefined) {
					return animations[t];
				}
			}
		})(document.createElement('div'));

		this.addClass('animated ' + animationName).one(animationEnd, function() {
			$(this).removeClass('animated ' + animationName);

			if (typeof callback === 'function') callback();
		});

		return this;
	},
});

//test

