var imageEnlarge = {

	toggle: function(event) {

		if ($('.isEnlarged').length > 0) {

			var scrollto = $('.isEnlarged').eq(0).data('scrollto');
			$('.isEnlarged').remove();
			$('main').show();
			window.scroll(0, scrollto);
		} else {
			event.preventDefault();

			var scrollpos =  $(this).offset().top + ($(this).height() /4);
			$(this).css({cursor: 'pointer'});
			var imageHtml = $(this).clone();
			$('main').hide();
			$('body').append(imageHtml);
			$('body > img:last-child').eq(0).addClass('isEnlarged')
				.css({width: '100vw', display: 'block'}).on('click', imageEnlarge.toggle)
				.data('scrollto', scrollpos);

		}
	}
}

$(document).ready(function() {
	$('.tmpl-lesson main img').on('click', imageEnlarge.toggle);
});

console.log('image enlarge loaded');