/**
 * bind mouse and key listening handler to scanner
 *
 *
 */

$.fn.mouseKeyListeningHandler = function(options){

	var defaults = {
		scanner: null,
	};

	var settings = $.extend({}, defaults, options);


	var state = {
		altDown: false,
		controlDown: false,
		rightMouseDown: false,
		leftMouseDown: false,
		locationY: 0,
		currentScreen: null,
		locDownX: null,
		locDownY: null,
		sneakPeekMode: false
	};

	var methods = {

		/**
		 * Setup key listeners
		 * @param scanner
		 */
		initKeyListeners: function (scanner) {

			document.addEventListener('keydown', function(event){

				if($(':focus').length > 0 && ( $(':focus')[0].nodeName === 'INPUT' || $(':focus')[0].nodeName === 'TEXTAREA')) {
					return;
				}


				var keyName = event.key;

				switch (keyName) {

						// Set window level met F4, F5 of F6
					case 'F4':
						$(scanner.settings.density.lung).trigger('click');
						break;
					case 'F5':
						$(scanner.settings.density.bone).trigger('click');
						break;
					case 'F6':
						$(scanner.settings.density.softtissue).trigger('click');
						break;

						// Set orientation met c, s of a
					case 'c':
						$(scanner.settings.orientation.frontal).trigger('click');
						break;
					case 's':
						$(scanner.settings.orientation.sagital).trigger('click');
						break;
					case 'a':
						$(scanner.settings.orientation.transversal).trigger('click');
						break;

						// Set segmentation met g-toets
					case 'g':
						$('.mode-button.button-segmentation').trigger('click');
						break;

					case 'p':

						$('.findings-container .select-pannel-button.selected').trigger('click');
						break;

						// zoom reset met 0-toest
					case '0':
						scanner.settings.screens[scanner.settings.selectedScreen].setZoomFactor(scanner.settings.screens[scanner.settings.selectedScreen], -1 * scanner.settings.screens[scanner.settings.selectedScreen].zoomFactor);
						break;

					case '*':
						if (event.ctrlKey && event.altKey) {
							state.sneakPeekMode = !state.sneakPeekMode;
							$('.scanner').trigger({type:'sneakPeek', active: state.sneakPeekMode});
						}
						break;
					case '8':
						if (event.ctrlKey && event.altKey && event.shiftKey) {
							state.sneakPeekMode = !state.sneakPeekMode;
							$('.scanner').trigger({type:'sneakPeek', active: state.sneakPeekMode});
						}
						break;


					case 'Control':
						state.controlDown = true;
						break;

					// default:
					// 	console.log(keyName);
				}
			});

			document.addEventListener('keyup', function(event){

				if($(':focus').length > 0 && ( $(':focus')[0].nodeName === 'INPUT' || $(':focus')[0].nodeName === 'TEXTAREA')) {
					return;
				}

				var keyName = event.key;

				switch (keyName) {
					case 'Control':
						state.controlDown = false;
						break;
				}

			});


		},

		initMouseListeners: function (scanner) {

			// Custom menu tonen bij rechtermuisklik

			$('.screen').on("contextmenu", function(e){
				e.preventDefault();

			}, false);

			function startFocusOut(){
				$(document).on("click",function(){
					$("#custom-menu").hide();
					$(document).off("click");
				});
			}

			$("#custom-menu .items > li").click(function(){
				if($(this).attr('id') === 'segmentation') {
					$('.mode-button.button-segmentation').trigger('click');
				}
			});

			// Double click -> splitscreen, fullscreen

			$('.screen').on('dblclick', function(e){
				$('.mode-button.button-screen-split').trigger('click');
			});

			// $('.screen').on('mousedown', (event) => {
			$('.screen').on('mousedown', function(event) {

				state.locDownX = event.clientX;
				state.locDownY = event.clientY;

				if(event.button === 2) {
					state.rightMouseDown = true;
					state.locationY = event.clientY;

					for(var i = 0; i < scanner.settings.screens.length; i++) {
						var scannerIndex = scanner.settings.screens[i].screenIndex;
						var clickedIndex = parseInt($(event.target).parents('.screen').data('number'));

						if (scannerIndex === clickedIndex) {
							state.currentScreen = scanner.settings.screens[i];
							break;
						}
					}
				} else if(event.button === 0) {
					state.leftMouseDown = true;

					if(state.controlDown) {
						$("#custom-menu").css("left", event.pageX);
						$("#custom-menu").css("top", event.pageY);
						$("#custom-menu").fadeIn(200, startFocusOut());
					}
				}

			});

			// $('.screen').on('mouseup', (event) => {
			$('.screen').on('mouseup', function(event) {
				event.preventDefault();

				if(event.button === 2) {
					state.rightMouseDown = false;

					//show menu
					if (event.clientX === state.locDownX && event.clientY === state.locDownY) {
						$("#custom-menu").css("left", event.pageX);
						$("#custom-menu").css("top", event.pageY);
						$("#custom-menu").fadeIn(200, startFocusOut());
					}

				} else if(event.button === 0) {
					state.leftMouseDown = false;
				}

			});

			$('.screen').on('mousemove', function(event){
				if(state.rightMouseDown) {
					slowScroll(event.clientY);
					event.preventDefault();
				}


			});

			function slowScroll(position) {

				if(Math.abs(position - state.locationY) > 9) {
					if(position > state.locationY) {
						state.currentScreen.scrollOnSlide('down', state.currentScreen);
					} else {
						state.currentScreen.scrollOnSlide('up', state.currentScreen);
					}

					state.locationY = position;
				}

			}
		},



		/**
		 *  init mouse handler
		 */
		init: function(){
			methods.initKeyListeners(settings.scanner);
			methods.initMouseListeners(settings.scanner);
		}

	};

	methods.init();

};