$(document).ready(function () {

	caseHandeler.init();

	var openItem = "empty";

});

var caseHandeler = {
	answers: {},
	caseNumber: '',
	noduleInfo: null,
	otherFindingsInfo: null,
	conclusions: null,
	numberText: ['no', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'],
	$openItem: null,
	nodulesFound: [],
	feedbackRound: 0,
	screeningResult: '',
	strategy: '',
	course: '',
	afterFeedback: false,
	scanner: null,
	nodulesMissed: false,
	sneakPeakModeExplained: 0,
	requireJS: '',

	init: function() {
		caseHandeler.openFirstItem();

		caseHandeler.caseNumber = $('.button-get-feedback').data('casenumber');
		caseHandeler.course = $('.course-information').data('course');

		// caseHandeler.loadPatientInformation(caseHandeler.openFirstItem);
		//caseHandeler.loadScreenResultOptions();

		//temporary for styling purposes
		// caseHandeler.tempUpdatNodules();


		$('.button-text-checklist').on('click', caseHandeler.postAnswer);
		$('.check-list-item').on('click', caseHandeler.onClickTitle);
		$('a.button-open-viewer').on('click', caseHandeler.openViewerHandler);
		$('a.button-finish-item').on('click', caseHandeler.finishItemHandler);
		$('.case-dropdown').change(caseHandeler.screeningResultSelected);
		$('.case-dropdown').next().next().change(caseHandeler.screeningResultSelected);
		$('.button-get-feedback').on('click', caseHandeler.loadFeedback);
		$('.button-re-check').on('click', caseHandeler.loadFeedback);
		// $('.button-case-finished').on('click', caseHandeler.logFinished);
		console.log('buttons bound');
	},

	getScannerUrl: function(event){

		var url = $(event.target).attr('data-scanner-url');
		if (!url) {
			url = $(event.target).parents('a').attr('data-scanner-url');
		}
		return url;
	},


	//** ============= Communication with scanner ===================================== **//


	getNoduleInfo: function() {
		return caseHandeler.noduleInfo;
	},
	getOtherFindingsInfo: function() {
		return caseHandeler.otherFindingsInfo;
	},
	receiveNoduleInfo: function (info) {

		console.log('casehandeler info: ', caseHandeler.noduleInfo);
		console.log("receive nodule info: ", info);

		if(caseHandeler.noduleInfo) {

			for (var i = 0; i < info.length; i++) {
				var newNodule = true;
				var receivedNoduleId = info[i].noduleId;

				for(var j = 0; j < caseHandeler.noduleInfo.length; j++) {

					if(receivedNoduleId == caseHandeler.noduleInfo[j].noduleId) {
						info[i].createEvent = caseHandeler.noduleInfo[i].createEvent;
						caseHandeler.noduleInfo[i] = info[i];
						newNodule = false;
					}

				}

				if(newNodule) {
					caseHandeler.noduleInfo.push(info[i]);
				}

			}

		} else {
			caseHandeler.noduleInfo = info;
		}

		console.log('casehandeler info after: ', caseHandeler.noduleInfo);

		caseHandeler.updateScanNodules();

		// console.log('casehandeler info: ', caseHandeler.noduleInfo);
		// console.log("receive nodule info: ", info);
		//
		// if(caseHandeler.noduleInfo) {
		// 	for (var i = 0; i < info.length; i++) {
		//
		// 		if (caseHandeler.noduleInfo[i]) {
		// 			if (caseHandeler.noduleInfo[i].createEvent) {
		//
		// 				console.log('casehandeler noduleInfo create event: ', caseHandeler.noduleInfo[i].createEvent);
		// 				console.log('received nodule info create event: ', info[i].createEvent);
		//
		// 				info[i].createEvent = caseHandeler.noduleInfo[i].createEvent;
		// 			}
		// 		}
		// 	}
		// }
		// //caseHandeler.noduleInfo = $.extend(true, {}, caseHandeler.noduleInfo, info);
		//
		// caseHandeler.noduleInfo = info;
		// console.log('caseHandeler.noduleInfo: ', caseHandeler.noduleInfo);
		// caseHandeler.updateScanNodules();
	},

	noduleRemoved: function(noduleId) {
		for(var j = 0; j < caseHandeler.noduleInfo.length; j++) {

			if (noduleId == caseHandeler.noduleInfo[j].noduleId) {
				caseHandeler.noduleInfo.splice(j, 1);
				caseHandeler.updateScanNodules();
				console.log('removed nodule: ', caseHandeler.noduleInfo);
			}
		}
	},

	receiveOtherFindingsInfo: function (info) {
		caseHandeler.otherFindingsInfo = info;
		console.log(info);
		caseHandeler.updateOtherFindings();
	},

	updateOtherFindings:function(){
		var $findingsData = $('.scan-findings-data.other-findings');
		var $findingsList = $findingsData.find('.scan-findings-list').text('');
		var $numberOfFindingsFound = $findingsData.find('.scan-findings-found').text('');
		var $numberOfFindingsFound2 = $findingsData.parents('.container-checklist-item').find('.content-closed').text('');

		var info = caseHandeler.otherFindingsInfo;

		var findingsCount = this.otherFindingsInfo.length;
		var summary = this.numberText[findingsCount] + ' other finding';
		if (findingsCount != 1) {
			summary += 's';
		}
		summary +=' found.';
		$numberOfFindingsFound.text(summary);
		$numberOfFindingsFound2.html('<i class="far fa-arrow-right"></i>' +summary);


		this.otherFindingsInfo.forEach(function(info){
			var html = '<li><div class="list-item-white"><p>Finding '+ info.findingId + '</p><p>'+ info.type +' | slice '+info.slice+'</p><p>' + info.notes + '</p></div></li> ';
			$findingsList.append(html);
		});

		$findingsData.parents('.container-checklist-item').toggleClass('display-findings',true);
	},

	updateScanNodules: function () {
		// console.log("updateNodules");
		var noduleCount = caseHandeler.noduleInfo.length;

		var $findingsData = $('.scan-findings-data.nodule-findings');
		var $noduleContainer = $findingsData.find('.nodule-container').text('');

		var $numberOfFindingsFound = $findingsData.find('.scan-findings-found').text('');
		var $numberOfFindingsFound2 = $findingsData.parents('.container-checklist-item').find('.content-closed').text('');

		var summary = this.numberText[0 + noduleCount] + ' nodule';
		if (noduleCount != 1) {
			summary += 's';
		}
		summary +=' found.';
		$numberOfFindingsFound.text(summary);
		$numberOfFindingsFound2.html('<i class="far fa-arrow-right"></i>' +summary);

		var jsonNodules = [];
		jsonNodules.push({feedbackRound: 0});

		if(caseNoduleData.scanDate.length > 1) {
			caseHandeler.updateCurrentAndPriorNodules($noduleContainer);
		} else {
			// console.log('scandate only 1?');
			caseHandeler.updateCurrentNodules($noduleContainer);
		}

		this.noduleInfo.forEach(function(noduleInfo, index){
			var title = 'Nodule ' + (index + 1);
			console.log('noduleInfo: ', noduleInfo);

			noduleInfo.nodeIndex = noduleInfo.createEvent.nodeIndex;
			noduleInfo.originalId = noduleInfo.createEvent['nodule_id'];
			noduleInfo.scanDate = noduleInfo.createEvent.scanDate;

			for (var key in noduleInfo) {
				if (noduleInfo.hasOwnProperty(key)) {
					// $findingsList.append('<input type="hidden" name="'+ key +'['+ noduleInfo.noduleId + ']" value="' + noduleInfo[key] + '">');
				}
			}

			var tempNodule = $.extend({}, noduleInfo);
			// console.log('noduleInfo after extend to tempnodule: ', noduleInfo);
			// console.log('tempNodule: ', tempNodule);

			tempNodule['label'] = "Nodule " + (index + 1);
			delete tempNodule.createEvent;
			jsonNodules.push({
				id: (index + 1),
				nodule: tempNodule
			});
		});
		$findingsData.parents('.container-checklist-item').toggleClass('display-findings',true);
		caseHandeler.nodulesFound = jsonNodules;

		console.log('nodulesfound: ', caseHandeler.nodulesFound);

		//Change max-height to accomodate the new amount of nodules
		var noduleContainerHeight = $('.scan-findings-data.nodule-findings').parent('.content-open').height() + 40;
		$('.scan-findings-data.nodule-findings').closest('.check-list-content').css('max-height', noduleContainerHeight);



	},

	getVolume: function($volume) {
		if(/\d/.test($volume)) {
			return $volume + ' mm3';
		} else {
			return 'not available';
		}
	},

	//Show nodules for cases that have a current and a prior scan.
	updateCurrentAndPriorNodules: function($noduleContainer) {

		console.log('noduleinfo: ', this.noduleInfo);
		var currentScanDate = currentDate;

		var priorScanDate = caseNoduleData.scanDate[0];

		$noduleContainer.append('<div class="nodule-item"><p>Current</p><p>scan: '+ currentScanDate.substring(6,8) + '-' + currentScanDate.substring(4,6) + '-0000</p></div>');
		$noduleContainer.append('<div class="nodule-item"><p>Prior</p><p>scan: '+ priorScanDate.substring(6,8) + '-' + priorScanDate.substring(4,6) + '-0000</p></div>');

		var priorNodules = $.extend({}, caseNoduleData.objectSettings[caseNoduleData.scanDate[0]].noduleinfo);
		var currentNodules = this.noduleInfo;

		console.log('currentNodules: ', currentNodules);
		console.log('priorNodules: ', priorNodules);

		if(priorNodules != null) {

			for(var i = 0; i < currentNodules.length; i++) {

				var title = 'Nodule ' + (i + 1);
				var priorNoduleFound = false;

				$.each( priorNodules, function( key, value ) {

					if (currentNodules[i].noduleId == value.id) {

						$noduleContainer.append('<div class="nodule-item noduleId-' + (i+1) + '"><div class="nodule-title">' + title + '</div><table><tr><td>Volume</td><td>' + currentNodules[i].createEvent.volume_correct + ' mm3</td></tr><tr><td>Type</td><td>' + currentNodules[i].type + '</td></tr><tr><td>VDT</td><td>' + currentNodules[i].createEvent.vdt_or_other_changes + '</td></tr></table></div>');
						$noduleContainer.append('<div class="nodule-item noduleId-' + (i+1) + '"><div class="nodule-title">' + title + '</div><table><tr><td>Volume</td><td>' + caseHandeler.getVolume(value.details.volume_correct) + '</td></tr><tr style="visibility: hidden;"><td>Type</td><td>' + value.details.density + '</td></tr><tr style="visibility: hidden;"><td>VDT</td><td>' + value.details.vdt_or_other_changes + '</td></tr></table></div>');

						delete priorNodules[key];
						priorNoduleFound = true;
					}
				});

				if(!priorNoduleFound) {
					$noduleContainer.append('<div class="nodule-item noduleId-'+ (i+1) +'"><div class="nodule-title">' + title + '</div><table><tr><td>Volume</td><td>' + currentNodules[i].createEvent.volume_correct + ' mm3</td></tr><tr><td>Type</td><td>' + currentNodules[i].type + '</td></tr><tr><td>VDT</td><td>'+ currentNodules[i].createEvent.vdt_or_other_changes +'</td></tr></table></div>');
					$noduleContainer.append('<div class="nodule-item empty"><div class="nodule-title empty">'+ title +'</div><table></table></div>');
				}
			}

			if(Object.keys(priorNodules).length > 0) {
				// console.log('priornodules left: ', priorNodules);

				var k = 0;
				$.each( priorNodules, function( key, value ) {
					// console.log( key, ": ", value );


					var title = 'Nodule ' + (currentNodules.length + k + 1);

					$noduleContainer.append('<div class="nodule-item"><div class="nodule-title empty">'+ title +'</div><table class="empty"></table></div>');
					$noduleContainer.append('<div class="nodule-item noduleId-'+ (currentNodules.length + k + 1) +'"><div class="nodule-title">' + title + '</div><table><tr><td>Volume</td><td>' + caseHandeler.getVolume(value.details.volume_correct) + '</td></tr><tr style="visibility: hidden;"><td>Type</td><td>' + value.details.density + '</td></tr><tr style="visibility: hidden;"><td>VDT</td><td>'+ value.details.vdt_or_other_changes +'</td></tr></table></div>');

					k++;
				});
			}

		} else {
			//For some reason priornodules cannot be found. Only show current.
			// console.log('priornodules if apparently null');
			caseHandeler.updateCurrentNodules($noduleContainer);
		}
	},

	//Show nodules for cases that only have a current.
	updateCurrentNodules: function($noduleContainer) {
		var currentScanDate = this.noduleInfo[0].createEvent.scanDate;

		$noduleContainer.append('<div class="nodule-item"><p>Current</p><p>scan: '+ currentScanDate.substring(6,8) + '-' + currentScanDate.substring(4,6) + '-0000</p></div>');
		$noduleContainer.append('<div class="nodule-item empty"><p>Prior</p><p>scan:</p></div>');

		var currentNodules = this.noduleInfo;

		for(var i = 0; i < currentNodules.length; i++) {

			var title = 'Nodule ' + (i + 1);

			// console.log('current nodule ', i, ': ', currentNodules[i]);
			$noduleContainer.append('<div class="nodule-item noduleId-'+ (i + 1) +'"><div class="nodule-title">' + title + '</div><table><tr><td>Type</td><td>' + currentNodules[i].type + '</td></tr><tr><td>Volume</td><td>' + currentNodules[i].createEvent.volume_correct + ' mm3</td></tr><tr><td>VDT</td><td>'+ currentNodules[i].createEvent.vdt_or_other_changes +'</td></tr></table></div>');
			$noduleContainer.append('<div class="nodule-item empty"><div class="nodule-title empty">'+ title +'</div><table></table></div>');

		}
	},

	setSneakPeakOptionExplained: function() {
		caseHandeler.sneakPeakModeExplained = 1;
	},

	//** ============= Navigation ===================================== **//

	openItem: function(itemSelector) {
		console.log('open item');

		$('.container-checklist-item.open').removeClass('open');

		caseHandeler.$openItem = $(itemSelector);

		if($(itemSelector).hasClass('feedback')) {
			caseHandeler.$openItem.addClass('stay-open');
		} else {
			caseHandeler.$openItem.addClass('open');
		}

	},
	//TODO is this still needed
	// closeItem: function(itemSelector) {
	// 	console.log('close item');
	// 	$(itemSelector).removeClass('open');
	// },
	toggleItem: function($itemSelector) {
		console.log('toggle item');

		if ($itemSelector.hasClass('open')) {
			$itemSelector.toggleClass('open');

		} else {
			$('.container-checklist-item.open').removeClass('open');
			$itemSelector.toggleClass('open');
		}
	},

	openFirstItem: function (event) {
		caseHandeler.openItem($('.container-checklist-item').eq(0));
	},

	onClickTitle: function(event){
		caseHandeler.toggleItem($(this).parents('.container-checklist-item'));
	},

	openViewerHandler: function(event) {
		event.preventDefault();
		console.log("open scanner");
		var title = $(this).parents('.container-checklist-item').find('h3').contents()
			.filter(function () {
				return this.nodeType === 3;
			}).eq(0).text();

		var sneakPeak = '';
		if(caseHandeler.nodulesMissed) {
			sneakPeak = '#!snkpk' + caseHandeler.sneakPeakModeExplained;
		}

		//open scanner
		caseHandeler.scanner = window.open(caseHandeler.getScannerUrl(event) + '#!' + encodeURI(title) + sneakPeak, '_blank');

	},

	finishItemHandler: function(event) {
		event.preventDefault();

		$(this).parents('.container-checklist-item').find('.case-item-icon').addClass('loader');
		caseHandeler.closeThisOpenNext(null, $(event.target),'');
	},
	//** ============= API CALLS ===================================== **//

	/*
	loadScreenResultOptions: function () {

		var content = $('#checklist-step-5').closest('.container-checklist-item').find('.content-open');
		var url = $(content).parent().attr('data-url');

		$.get(url, function (html) {

			var menu1;
			var menu2;

			//get dropdown menu's
			$('.case-dropdown', content).each(function (k) {
				if (k == 0) {
					menu1 = $(this);
				} else {
					menu2 = $(this);
				}
			});

			//Only set options once
			if ($(menu1).find('option').length == 1) {
				//reparse html
				$('ul', html).each(function (j) {

					$(this).find('li').each(function (i, item) {
						var text = $(item).text();
						var id = text.split('|')[0];
						var option = text.split('|')[1];

						if (j > 0) {
							//text for second dropdown menu
							menu2.append('<option value=' + id + '>' + option + '</option>')
						} else {
							menu1.append('<option value=' + id + '>' + option + '</option>')

						}

					});
				});
			}

		});
	},*/


	// closeItem: function (content) {
	// 	console.log('closeItem');
	// 	if ($(openItem).hasClass('finished')) {
	// 		//animate hight and background-color
	// 		var wrapperClosed = $(openItem).find('.wrapper-content-closed');
	// 		$(openItem).height(wrapperClosed.height() + 10); //margin p 10
	// 		$(openItem).css('background-color', '#FFFFFF');
	// 	} else {
	// 		$(openItem).height(0);
	// 	}
	//
	// 	setTimeout(
	// 		function () {
	// 			$(openItem).removeClass('open');
	// 			openItem = content;
	// 		}, 400);
	// },

	//Sets the answer text when an option is selected, and the next step is being activated
	setAnswerText: function (conclusion, contentClosed) {
		var string = conclusion.replace(/(?:\r\n|\r|\n)/g, '<br /><i class="far fa-arrow-right"></i>');

		$(contentClosed).empty();
		$(contentClosed).append('<i class="far fa-arrow-right"></i>' + string);
	},

	//Handle screening result select inputs and enable the finish button when options are selected
	screeningResultSelected: function (event) {
		$('.case-dropdown').each(function (i) {
			if (i == 0) {
				caseHandeler.screeningResult = $(this).find(":selected").val();
			} else {
				caseHandeler.strategy = $(this).find(":selected").val();
			}
		});

		var button = $(this).closest('.content-open').find('.button-text-checklist');

		if (caseHandeler.screeningResult != 'select' && caseHandeler.strategy != 'select') {
			//Make button clickable
			if ($(button).hasClass('disabled')) {
				$(button).removeClass('disabled');
				//console.log('remove disabled');
			}
		} else {
			//Make button unclickable
			if (!($(button).hasClass('disabled'))) {
				$(button).addClass('disabled');
			}
		}
	},

	postAnswer: function (e) {
		e.preventDefault();
		console.log("postanswer");

		var postAnswerUrl = '/cases/answer';
		var clickedButton = this;
		var answer;

		//show loader
		var iconPosition = $(this).closest('.container-checklist-item').find('.case-item-icon');
		$(iconPosition).addClass('loader').removeClass('case-item-icon animateImage largeSmall');

		if ($(this).data("questionnumber") == 5) {
			//change answer type to enable sending 2 answers
			answer = [caseHandeler.screeningResult, caseHandeler.strategy];
		} else {
			answer = $(this).data("answer");
		}
		caseHandeler.answers['question-' + $(this).data('questionnumber')] = answer;

		var formData = {
			caseNumber: $(this).data("casenumber"),
			questionNumber: $(this).data("questionnumber"),
			answer: answer,
			_token: $(this).data("_token"),
		};

		caseHandeler.postData(formData, postAnswerUrl, function (data) {
			// zet tekst op goede locatie
			var json = JSON.parse(JSON.stringify(data));
			var conclusion = json.conclusion;
			var nextStep = json.nextStep;
			var nodules = json.nodules;
			var findings = json.findings;

			caseHandeler.findNextStep(conclusion, clickedButton, nextStep, nodules, findings);

		}, 'json');
	},


	findNextStep: function (conclusion, clickedButton, nextStep, nodules, findings) {
		console.log("find next step", nextStep);

		switch (nextStep) {
			case "read notes":
				caseHandeler.setTitlesTransparent(false);
				caseHandeler.closeThisOpenNext(conclusion, clickedButton, '');
				break;

			case "evaluate nodules":
				caseHandeler.closeThisOpenNext(conclusion, clickedButton, '');
				break;

			case "register findings":
				caseHandeler.showFindings(conclusion, nodules, '#checklist-step-3');
				break;

			case "derive screen results":
				caseHandeler.showFindings(conclusion, findings, '#checklist-step-4');
				break;

			case "get feedback":
				caseHandeler.closeThisOpenNext(conclusion, clickedButton, '');
				break;

			case "not qualified":
				caseHandeler.setTitlesTransparent(true);
				caseHandeler.closeThisOpenNext(conclusion, clickedButton, '.feedback');
				break;
		}

	},

	setTitlesTransparent: function (transparent) {
		//When the user choses 'no' as his answer to question 1. Set all other questions transparent and unclickable or vice versa when 'yes' is chosen.

		for (var i = 2; i < 6; i++) {
			if (transparent) {
				$('#checklist-step-' + i).closest('.container-checklist-item').addClass('transparent');
			} else {
				$('#checklist-step-' + i).closest('.container-checklist-item').removeClass('transparent');
			}

		}
	},

	// closeFeedback: function () {
	//
	// 	//Only close feedback-section if get-feedback has not been pressed yet.
	// 	//console.log("feedback display= " + $('.get-feedback').css('display'));
	// 	if ($('.get-feedback').css('display') != "none") {
	// 		var feedback = $('.completed-checklist');
	// 		$(feedback).css("display", "none");
	// 	}
	// },

	showFindings: function (conclusion, items, step) {
		console.log("showfindings");

		$(step).find('.loader').removeClass('loader').addClass('case-item-icon ');

		var findingsData = $(step).closest('.container-checklist-item').find('.scan-findings-data');
		var findingsList = $(step).closest('.container-checklist-item').find('.scan-findings-list');
		var numberOfFindingsFound = $(step).closest('.container-checklist-item').find('.scan-findings-found');

		var contentQuestion = $(step).closest('.container-checklist-item').find('.content-question');
		var checklistContent = $(step).closest('.container-checklist-item').find('.check-list-content');
		var contentClosed = $(checklistContent).find('.content-closed');

		$(findingsList).empty();
		$(numberOfFindingsFound).empty();

		$(contentQuestion).css('display', 'none');
		$(findingsData).css('display', 'inherit');

		$(numberOfFindingsFound).append(conclusion);

		for (var i = 0; i < items.length; i++) {
			$(findingsList).append('<li><div class="list-item-white"><p>' + items[i].title + '</p><p>' + items[i].subtitle + '</p></div></li>');
		}

		var wrapper = $(checklistContent).find('.wrapper');
		$(checklistContent).height(wrapper.height());

		caseHandeler.setAnswerText(conclusion, contentClosed);

	},

	closeThisOpenNext: function(conclusion, button, next){
		//var $content = $(button).closest('.check-list-content');
		var $container = $(button).parents('.container-checklist-item');
		var title = $(button).closest('.container-checklist-item').find('.check-list-item');
		var $nextContainer;

		if(next === '') {
			$nextContainer = $container.next();
		}else{
			$nextContainer = $(next)
		}

		if (conclusion) {
			caseHandeler.setAnswerText(conclusion, $container.find('.wrapper-content-closed'));
		}

		$container.addClass('done');
		$container.find('.loader').closest('.check-list-item').addClass('done');
		$container.find('.loader').removeClass('loader').addClass('case-item-icon animateImage largeSmall');


		setTimeout(
			function () {
				$container.toggleClass('finished', true).toggleClass('open', false);
			}, 400);

		//If a new answer is chosen after feedback is given,
		// simply close this item and change title colors.
		// if (!caseHandeler.afterFeedback) {
	 		caseHandeler.openItem($nextContainer);
		// }

		if (caseHandeler.isAfterFeedback(button)) {
			$(title).removeClass('check-list-item-wrong');
			$(title).find('#wrong-item-information').css("display", "none");
			caseHandeler.resetFeedback();
		}

		//make next item clickable
		$nextContainer.removeClass('disable-click')

	},

	loadFeedback: function (event) {
		event.preventDefault();

		var postAnswerUrl = '/cases/feedback';

		var conclusions = {
			screeningResult: $('.select-screening-result').find(":selected").text(),
			strategy: $('.select-strategy').find(":selected").text()
		}

		console.log(caseHandeler.noduleInfo);
		console.log(caseHandeler.nodulesFound);

		var formData = $.extend({},{
			caseNumber: caseHandeler.caseNumber,
			_token: $(this).data("_token"),
			answers:caseHandeler.answers,
			conclusions: conclusions,
			nodules: caseHandeler.nodulesFound,
			findings: caseHandeler.otherFindingsInfo,
		} );

		if (formData.findings) {

			formData.findings.forEach(function(finding, id){
				finding.createEvent = null;
			})
		}
		caseHandeler.postData(formData, postAnswerUrl, function (data) {

			var result = {};
			result['feedback'] = data;
			result['formData'] = formData;

			console.log('result', result);

			//var json = JSON.parse(JSON.stringify(data));
			caseHandeler.showFeedback(result,'submit');

		}, 'json');
	},

	showFeedback: function (json, moment) {

		if(caseHandeler.nodulesFound.length === 0) {
			caseHandeler.nodulesFound.push({'feedbackRound': caseHandeler.feedbackRound });
		} else {
			caseHandeler.nodulesFound[0].feedbackRound = caseHandeler.feedbackRound;
		}

		//Log de gevonden nodules
		logActivity('case',caseHandeler.caseNumber,'nodules found',caseHandeler.nodulesFound, caseHandeler.course);

		var time = Math.round(Math.abs(endDateCase - startDateCase) / 1000);
		json['feedback'].push({'time':time});
		logActivity('case',caseHandeler.caseNumber, moment, json, caseHandeler.course);
		console.log('show feedback');
		var allCorrect = true;
		$.each(json['feedback'], function (key, value) {
			var checkstep = '#checklist-step-' + value.questionId;
			if ( value.questionId == 5 && caseHandeler.course =='radia') {
				 checkstep = '#checklist-step-4';
			}

			//reset wrong-item-warning text
			$(checkstep).find('#wrong-item-information').css("display", "none");


			//set the correct and wrong item colours
			if (value.correct == 1 || value.time) {
				$(checkstep).addClass('check-list-item-right');
				$(checkstep).closest('.container-checklist-item').find('.feedback-warnings').css('display', 'none');

			} else {
				allCorrect = false;
				console.log(value.questionId);
				$(checkstep).addClass('check-list-item-wrong');
				$(checkstep).find('#wrong-item-information').css("display", "inline-block");

				$(checkstep).closest('.container-checklist-item').find('.feedback-warnings').css('display', 'inherit');
				var feedbackLocation = $(checkstep).closest('.container-checklist-item').find('.feedback-warnings').children(":first");

				$(feedbackLocation).empty();

				$.each(value.feedback, function (feedbackKey, feedbackValue) {
					//set the feedbacktext for each item
					if( feedbackValue.link != "") {
						if(caseHandeler.course == 'demo') {
							$(feedbackLocation).append('<li>' + feedbackValue.text + '</li><li class="no-dot demo-warning"><span class="demo-feedback-text">' + feedbackValue.subtext + '</span><a target="_blank" href="' + feedbackValue.link + '">click here</a><span class="warning-subtext"><br>Sign up for the full course to benefit from this feature.</span></li>');
						} else {
							$(feedbackLocation).append('<li>' + feedbackValue.text + '</li><li class="no-dot">' + feedbackValue.subtext + '<a target="_blank" href="' + feedbackValue.link + '">click here</a></li>');
						}

					}else{
						$(feedbackLocation).append('<li>' + feedbackValue.text + '</li><li class="no-dot">' + feedbackValue.subtext + '</li>');
					}

					if(feedbackValue.missedNodule != null) {
						console.log("missedNodule value: ", feedbackValue.missedNodule);
						caseHandeler.nodulesMissed = true;
					}

				});
			}
		});

		// $('.completed-checklist').removeClass('first-time');
		$('.get-feedback').css('display','none');

		if (allCorrect) {


			console.log('find expert notes', json['feedback']);
			$('.feedback-with-errors').addClass('hidden');
			$('.feedback-success').removeClass('hidden');
			$('#checklist-step-6').addClass('done').addClass('check-list-item-right');

			caseHandeler.logFinished();
		} else {

			$('.feedback-with-errors').removeClass('hidden');
			$('.feedback-success').addClass('hidden');
		}

		//Change max-height to accomodate the feedback and nodules
		var noduleContainerHeight = $('.scan-findings-data.nodule-findings').parent('.content-open').height();
		if(noduleContainerHeight > 0) {
			noduleContainerHeight = noduleContainerHeight + 40;
			$('.scan-findings-data.nodule-findings').closest('.check-list-content').css('max-height', noduleContainerHeight);
		}

		caseHandeler.afterFeedback = true;
		caseHandeler.feedbackRound++;

		console.log('casehandeler.scanner: ', caseHandeler.scanner);

		if(caseHandeler.nodulesMissed && caseHandeler.scanner != null && !caseHandeler.scanner.closed) {
			caseHandeler.scanner.scannerRepo.methods.showSneakPeekButton('snkpk' + caseHandeler.sneakPeakModeExplained);
		}

	},

	logFinished: function (event) {
		var time = Math.round(Math.abs(endDateCase - startDateCase) / 1000);
		var timeObject = [{time:time}];

		logActivity('case',caseHandeler.caseNumber,'finished', timeObject, caseHandeler.course);

	},

	isAfterFeedback: function (button) {
		var title = $(button).closest('.container-checklist-item').find('.check-list-item');
		//var title = $('#checklist-step-2');
		if ($(title).hasClass('check-list-item-wrong') || $(title).hasClass('check-list-item-right')) {
			return true;
		} else {
			return false;
		}
	},

	resetFeedback: function(){
		if(!$('.feedback-success').hasClass('hidden')) {
			$('.get-feedback').css('display', 'inline-block');
			$('.feedback-with-errors').addClass('hidden');
			$('.feedback-success').addClass('hidden');
			$('#checklist-step-6').removeClass('done').removeClass('check-list-item-right');
		}
	},

	postData: function (data, url, callback, dataType) {
		if (!dataType) {
			dataType = 'HTML';
		}
		$.ajax({
			url: url,
			data: data,
			method: 'POST',
			xhrFields: {
				withCredentials: true
			},
			dataType: dataType
		}).done(callback);
	}

};
window.caseHandeler = caseHandeler;
