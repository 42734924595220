var questionRepositoryMethods = {
    settings:{},
    init: function(options) {
        var defaults = {
            submitButton: null,
            getQuestionUrl: '/lesson/question',
            postAnswerUrl: '/lesson/answer'
        };

        questionRepositoryMethods.settings = $.extend( {}, defaults, options );
        $(this).data('questionRepo', questionRepositoryMethods);
    },
    postData: function($this, data, url, callback, dataType) {
        if (!dataType){
            dataType = 'HTML';
        }
        $.ajax({
            url: url,
            data: data,
            method: 'POST',
            xhrFields: {
                withCredentials: true
            },
            context:$this,
            dataType: dataType
        }).done(callback);
    },
    loadQuestion: function() {
        var $this =  $(this);
        var repository = $this.data('questionRepo');
        var settings = repository.settings;
        var pageNumber = $this.data('page-number');
        var pageId = $this.data('page-id');
        var courseCodeChapterNumber =  $this.data('course-code-chapter-number');
        var token = $this.data('_token');

        var dataObject = {
            pageNumber: pageNumber,
            _token: token,
            pageId: pageId,
            courseCodeChapterNumber: courseCodeChapterNumber
        };
        console.log(settings.getQuestionUrl);
        repository.postData($this, dataObject, settings.getQuestionUrl,

            function(html) {
                if (html === 'no questions found') {
					// @todo: De volgende les toe staan moet logisch worden geregeld
					$('.next-lesson').removeClass('disabled');
                } else {
                    // console.log('data loaded');
                    // console.log($(this));
                    $(this).html(html);
                    $(this).questionRepo('bindButtons');
                }
            }
		);

    },
    bindButtons: function() {
        var $this = $(this);
        var repo =  $this.data('questionRepo');

        //In multiple choice questions: answerbutton disabled as long as no answer is chosen
		if($('.question input[name=questionType]').val() == 3) {
			$('.answersubmit').addClass('disabled');
			$('.question input[type=radio]').on('click', function() { $('.answersubmit').removeClass('disabled') });
		}

        $('.answersubmit').on('click',repo.postAnswer($this));

		if (collapseBoxClickHandler !== undefined) {
            collapseBoxClickHandler = window.collapseBoxClickHandler;
        }

        $('.collapse-box').on('click', collapseBoxClickHandler);
        $this.questionRepo('bindAnswerButtons');
        $this.questionRepo('bindNextQuestion');
        if ($('.full-width-container .button-text,.full-width-container .answersubmit').length == 0) {
            $('.next-lesson').removeClass('disabled');
        }
    },


    postAnswer: function($context){

        var $this = $context;
        return function(e){

            var settings =  $this.data('questionRepo').settings;
            e.preventDefault();
            var formData = {
                courseCodeChapterNumber: $('.question input[name=courseCodeChapterNumber]').val(),
                pageNumber: $('.question input[name=step]').val(),
                contentId: $('.question input[name=contentId]').val(),
                questionType: $('.question input[name=questionType]').val(),
                _token: $('.question input[name=_token]').val(),
                answer: $('.question textarea[name=answer]').val(),
                answerOptionId: $(".question input:radio[name=answerOptionId]:checked").val(),
                questionsLeft: $(".question input[name=questionsLeft]").val()
            };
            $this.data('questionRepo').postData($this, formData, settings.postAnswerUrl, function(html){
                $(this).html(html);
                $(this).questionRepo('bindButtons');
            } );

            if (formData.questionType != 1 && formData.questionsLeft < 2) {
                $('.next-lesson').removeClass('disabled');
            }

        }
    },
    bindNextQuestion: function (){
        var $this = $(this);
        var repository = $this.data('questionRepo');
        var settings =  repository.settings;
        $('.next-question').on('click', function(e) {
            e.preventDefault();
            $this.questionRepo('loadQuestion');
        });
    },
    bindAnswerButtons: function() {
        var $this = $(this);
        var repository = $this.data('questionRepo');
        var settings =  repository.settings;

        $('.answer-buttons a').on('click', function(e){
            e.preventDefault();
            var right = $(this).data('right');
            var hash = $(this).data('answerhash');
            var token = $('.question').data('_token');
            var courseCodeChapterNumber = $('.question').data('course-code-chapter-number');
            var pageNumber = $('.question').data('page-number');
            var contentId = $('.question-container').data('content-id');
            var questionsLeft = $('.question-container').attr('data-questionsLeft');

            var data = {
                isRight: right,
                answerhash: hash,
                courseCodeChapterNumber: courseCodeChapterNumber,
                pageNumber: pageNumber,
                contentId: contentId,
                _token: token,
				questionsLeft: questionsLeft
            };
            $('.answer-buttons a').hide();
            repository.settings.activeButtonClass = $(this).attr('class');
            $(this).show().addClass('active');

            repository.postData($this, data, settings.postAnswerUrl, function(html){
                var repository = $(this).data('questionRepo');
                $(this).html(html);
                $('.answer-buttons a').hide();
                $('.'+repository.settings.activeButtonClass).show().addClass('active');
                // @todo: De volgende les toe staan moet logisch worden geregeld
				if (data.questionsLeft < 2) {
					$('.next-lesson').removeClass('disabled');
				}
                $(this).questionRepo('bindButtons');
            } );

        });
    }
}

$.fn.questionRepo = function( method ) {

    if (questionRepositoryMethods[method]) {
        return questionRepositoryMethods[method].apply(this, Array.prototype.slice.call(arguments, 1));
    } else if (typeof method === 'object' || !method) {
        return questionRepositoryMethods.init.apply(this, arguments);
    } else {
        $.error('Method ' + method + ' does not exist on jQuery.questionRepo');
    }
}

$(document).ready(function() {
    if( $('.question').length) {
        $('.question').questionRepo({});
        $('.question').questionRepo('loadQuestion');
    } else {
        // wanneer er geen vragen zijn wordt de next lesson button enabled
		$('.next-lesson').removeClass('disabled');
    }
});

