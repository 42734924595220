
var overlay = {

	getData:function(){

		var courseCode = $('.overlay').data('course-code');
		var chapterNumber = $('.overlay').data('chapter-number');

		axios.get('/api/progress/current?coursecode=' +courseCode +'&chapternumber=' +chapterNumber)
			.then(function (response) {

				$('.progress').css('width', response.data.progressCourse +'%');

				$('#course-progress-text').text( response.data.progressCourse +'%');

				$('.progress-indicator').css('left', 'calc( ' +response.data.progressCourse +'% - 101px)');
				$('#statistics-question-text').text(response.data.questionProgress['correct_percentage']);


				return true;
			})
			.catch(function (error) {
				console.log("error:" + error);
				return true;
			});
	},
	show:function(){
		overlay.getData();
		$('#next-lesson-button').attr("href", $('#finish-lesson-button').attr('href'));
		$('main').addClass('blur');
		$('html, body').animate({scrollTop : 0},500);
		$('.overlay').addClass('show');

	},
	initOverlay: function (event){
		event.preventDefault();
		overlay.updateHeight();
		var currentLesson = $('.content').data('lesson');
		var currentPage = $('.content').data('pagenumber');
		var lessonPage = currentLesson + "." + currentPage;
		var courseCode = $('.overlay').data('course-code');



		logActivity('lesson-page', lessonPage, 'finished', '', courseCode).then( function (value) {
			logActivity('lesson', currentLesson, 'finished', '', courseCode).then( function (value2) {
				overlay.show()

			});
		});

	},
	updateLogs: function (){

	},
	updateHeight: function () {
		$('.overlay').css('height', $(document).height());
	}


}
window.overlay = overlay;

$(document).ready(function() {
	//overlay.updateHeight();

	if($('#finish-lesson-button')){
		$('#finish-lesson-button').on('click',overlay.initOverlay);
	}

});

