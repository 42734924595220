/**
 * bind mouse zoom handler to screen
 *
 *
 *  options: {
 *  	zoomCallback: function
 *
 *  }
 */
$.fn.mouseZoomHandler = function(options){

	var defaults = {
		screen: this,
		$clickContainer: null,
		zoomCallback:  null,
		panCallback: null
	};

	var settings = $.extend({}, defaults, options);


	var state = {
		leftButtonDown: false,
		mouseDownPosition: {
			x: 0,
			y: 0
		},
		rectangle: {},
		spaceDown: false,
		panPosition: {
			x: 0,
			y: 0
		},
		mouseDownStart: {
			x:0,
			y:0
		},
		clientStart: {
			x:0,
			y:0
		}
	};

	var methods = {
		/**
		 * activate mouse down listener
		 * @param bool activate
		 */
		activateMouseDownListener: function(activate){
			if (activate) {
				$(settings.$clickContainer).on('mousedown', methods.mouseDownListener);
			} else {
				$(settings.$clickContainer).off('mousedown', methods.mouseDownListener);
			}
		},

		/**
		 * activate space down listener
		 * @param bool activate
		 */
		activateSpaceDownListener: function(activate){
			if (activate) {
				$(document).on('keydown', methods.spaceDownListener);
			} else {
				$(document).off('keydown', methods.spaceDownListener);
			}
		},

		/**
		 * activate space up listener
		 * @param bool activate
		 */
		activateSpaceUpListener: function(activate){
			if (activate) {
				$(document).on('keyup', methods.spaceUpListener);
			} else {
				$(document).off('keyup', methods.spaceUpListener);
			}
		},

		/**
		 * activate mouse move listener
		 * @param bool activate
		 */
		activateMouseMoveListener: function(activate){
			if (activate) {
				$(settings.$clickContainer).on('mousemove', methods.mouseMoveListener);
			} else {
				$(settings.$clickContainer).off('mousemove', methods.mouseMoveListener);
			}
		},

		/**
		 * activate mouse up listener
		 * @param bool activate
		 */
		activateMouseUpListener: function(activate){
			if (activate) {
				$(document).on('mouseup', methods.mouseUpListener);
			} else {
				$(document).off('mouseup', methods.mouseUpListener);
			}
		},

		/**
		 * mouse down listener
		 * @param event
		 */
		mouseDownListener: function (event) {

			// is left?
			if (event.button != 0) {
				return;
			}

			methods.activateMouseDownListener(false);
			var rectangle = event.target.getBoundingClientRect();

			// //console.log(rectangle,  event.clientX,  event.clientY, event);

			var context = methods.getContextualMouseCoordinates(event);
			state.mouseDownStartPositionRatio  = context.ratio;

			state.mouseDownPosition.x = event.clientX - rectangle.left;
			state.mouseDownPosition.y = event.clientY - rectangle.top;
			state.rectangle = rectangle;

			state.leftButtonDown = true;
			methods.activateMouseUpListener(true);
			methods.activateMouseMoveListener(true);
			state.panPosition = {x:0, y:0};

			state.mouseDownStart.x = state.mouseDownPosition.x;
			state.mouseDownStart.y = state.mouseDownPosition.y;
			state.clientStart.x = 0;
			state.clientStart.y = 0;

		},




		/**
		 * space up listener
		 * @param event
		 */
		spaceUpListener: function(event){
			if (event.keyCode != 32) {
				return;
			}
			state.spaceDown = false;
			methods.activateSpaceDownListener(true);
			methods.activateSpaceUpListener(false);
		},


		/**
		 * space down listener
		 * @param event
		 */
		spaceDownListener: function(event){
			if (event.keyCode != 32 || ($(':focus').length > 0 && ( $(':focus')[0].nodeName === 'INPUT' || $(':focus')[0].nodeName === 'TEXTAREA'))) {
				return;
			}
			event.preventDefault();
			// //console.log('space, the final fronteer');
			state.spaceDown = true;

			methods.activateSpaceDownListener(false);
			methods.activateSpaceUpListener(true);

		},

		/**
		 * mouse up listener
		 * @param event
		 */
		mouseUpListener: function(event) {
			if (event.button != 0) {
				return;
			}
			methods.activateMouseUpListener(false);
			methods.activateMouseMoveListener(false);
			state.leftButtonDown = false;
			methods.activateMouseDownListener(true);
			//methods.getContextualMouseCoordinates(event);
			state.clientStart.x = 0;
			state.clientStart.y = 0;
		},
		/**
		 * mouse move listener
		 * @param event
		 */
		mouseMoveListener: function(event) {
			if (!state.leftButtonDown) {
				return;
			}

			if (settings.$clickContainer.nodeName != event.target.nodeName) {
				return;
			}

			var mouseX = event.clientX - state.rectangle.left;
			var mouseY = event.clientY - state.rectangle.top;



			var canvasRectangle = event.target.getBoundingClientRect();
			state.clientStart.x = (canvasRectangle.left ) + (canvasRectangle.width * state.mouseDownStartPositionRatio.x);
			state.clientStart.y = (canvasRectangle.top ) + (canvasRectangle.height * state.mouseDownStartPositionRatio.y);
			if (state.spaceDown) {
				// handle pan

				if (state.panPosition.x == 0 && state.panPosition.y == 0) {
					state.panPosition.x = mouseX;
					state.panPosition.y = mouseY;
				} else {
					settings.panCallback(settings.screen[0], (mouseX - state.panPosition.x), (mouseY - state.panPosition.y));
					state.panPosition = {x:mouseX, y:mouseY};
				}

			} else {
				// handle zoom


				// als ik zoom,dan klik ik eerst
				// ik bepaal waar geklikt is op de plaat en op het scherm

				// tijdens het zoomen wordt de plaats op het scherm en de plaat op dezelfde plek
				// gehouden door te schuiven




//console.log('state.clientStart.y',state.clientStart.y);
				var context = methods.getContextualMouseCoordinates(event);

				// sum of the movement ratio in x an y direction
				var ratioCombi =((mouseY - state.mouseDownPosition.y) / state.rectangle.height);

				settings.zoomCallback(settings.screen[0], ratioCombi, state.mouseDownStartPositionRatio, state.clientStart, event);
				state.mouseDownPosition.x = mouseX;
				state.mouseDownPosition.y = mouseY;

			}
		},
		getContextualMouseCoordinates: function(event) {

			var canvasHTML = event.target;

			var rectangle = canvasHTML.getBoundingClientRect();
			var zoomFactor = parseFloat($(canvasHTML).parents('.canvascontainer').attr('data-zoomfactor'));
			if (!zoomFactor){
				zoomFactor = 1;
			}
			var zoomMouseX = event.clientX - rectangle.left;
			var zoomMouseY = event.clientY - rectangle.top;
			var ratio = {
				x:zoomMouseX/rectangle.width,
				y:zoomMouseY/rectangle.height
			};

			var convertedCoordinates = {
				window: {
					x: zoomMouseX,
					y: zoomMouseY
				},
				ratio: ratio,
				rectangle: rectangle,
				screen: {
					x: event.screenX,
					y: event.screenY
				}
			};
			// //console.log('convertedCoordinatesX: ', convertedCoordinates.ratio.x);
			// //console.log('rect.width: ', rectangle.width);
			// //console.log('ratio.x: ', ratio.x);
			//
			// //console.log('convertedCoordinates: ', convertedCoordinates);

			return convertedCoordinates;
		},

		/**
		 *  init mouse handler
		 */
		init: function(){
			methods.activateMouseDownListener(true);
			methods.activateSpaceDownListener(true);
		}
	};

	methods.init();
};
