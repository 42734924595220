$(document).ready(function() {

	if($('.scroll-fixed-container')[0]){
		initScrollFixed();
		//update();
	}

});

var stickyHeaderTop;
var stickyHeaderWidth;
var rt;


function initScrollFixed(){
	stickyHeaderTop = $('.scroll-fixed-container').offset().top;
	stickyHeaderWidth = $('.scroll-fixed-container').outerWidth();
	rt = ($(window).width() - ($('.scroll-fixed-container').offset().left + $('.scroll-fixed-container').outerWidth()));

	$(window).scroll(function(){

		update();
	});

	$( window ).resize(function() {

			$('.scroll-fixed-container').css({position: 'absolute', top: '0px', right: '0px', width: '100%', 'z-index':'99'});

			stickyHeaderTop = $('.scroll-fixed-container').offset().top;
			stickyHeaderWidth = $('.scroll-fixed-container').outerWidth();
			rt = ($(window).width() - ($('.scroll-fixed-container').offset().left + $('.scroll-fixed-container').outerWidth()));

			$('.scroll-fixed-container').css({
				position: 'fixed',
				top: '20px',
				width: stickyHeaderWidth,
				right: rt + 'px'
			});

			update();
	});
}

function update(){

	if( $(window).scrollTop() + 20 > stickyHeaderTop ) {
		$('.scroll-fixed-container').css({position: 'fixed', top: '20px', width:stickyHeaderWidth, right:rt+'px','z-index':'99'});

	} else {
		$('.scroll-fixed-container').css({position: 'absolute', top: '0px', right:'0px', width:'100%','z-index':'99'});
	}

}