$.fn.newsletterSignUp = function(options) {

	var defaults = {
		nameFieldSelector:  	'.name',
		emailFieldSelector:  	'.email',
		submitButtonSelector: 	'.submit-button',
		loaderSelector: 		'.loader',
		messageAreaSelector:	'.message',
		postUrl:				'/newsletter-subscribe',
		successMessage:			'Bedankt voor je aanmelding voor onze nieuwsbrief!'

	};
	var settings = $.extend({}, defaults, options);

	var isValidEmailAddress = function(email) {
		var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
		return pattern.test(email);
	};

	this.each(function(){
		var $this = $(this);
		$this.data('settings', settings);


		function setupField($field) {
			$field
				.focus(function () {
					$(this).removeClass('error');
				})
				.focusout(function () {
					if ($(this).attr('type') === 'text' && $(this).val().length === 0) {
						$(this).addClass('error');
					}
					else if ($(this).attr('type') === 'email' && !isValidEmailAddress($(this).val())) {
						$(this).addClass('error');
					}
					else {
						$(this).removeClass('error');
					}
				})
				.keyup(function (e) {
					var validated = false;

					if (isValidEmailAddress($(settings.emailFieldSelector).val())) {
						if (settings.nameFieldSelector > ''  ) {
							if ($(settings.nameFieldSelector).val().length !== 0) {
								validated = true;
							}
						} else {
							validated = true;
						}
					}

					if (validated) {
						$(settings.submitButtonSelector, $this).removeClass('disabled');

					} else {
						$(settings.submitButtonSelector, $this).addClass('disabled');

					}

					if (e.which == 13) {
						if ($(this).is($(settings.nameFieldSelector))) {
							$(settings.emailFieldSelector).focus();
						} else if ($(this).is($(settings.emailFieldSelector)) && validated) {
							$(settings.submitButtonSelector, $this).trigger('click');
						}
					}
				});

		};

		setupField($(settings.emailFieldSelector, $this));
		if (settings.nameFieldSelector > '') {
			setupField($(settings.nameFieldSelector, $this));
		}


		$(settings.submitButtonSelector, $this).click(function(e) {
			e.preventDefault();

			var validated = false;

			if (isValidEmailAddress($(settings.emailFieldSelector).val())) {
				if (settings.nameFieldSelector > '') {
					if ($(settings.nameFieldSelector).val().length !== 0) {
						validated = true;
					}
				} else {
					validated = true;
				}
			} else {
				$('.wrong-email-message').show();
				$(settings.emailFieldSelector).attr('background', 'red');
			}

			if (validated)
			{
				$(this).hide();
				$(settings.emailFieldSelector).prop('disabled', true);
				$(settings.emailFieldSelector).hide();

				$('.wrong-email-message').hide();
				$('.loader').show();

				var name  = '';
				var email = '';
				if ( settings.nameFieldSelector > ''){
					name = $(settings.nameFieldSelector).val();
				}
				if ( settings.emailFieldSelector > ''){
					email = $(settings.emailFieldSelector).val();
				}

				data = {
					name : name,
					email : email
				};
				if ($('input[name=_token]', $this).length){
					data._token = $('input[name=_token]', $this).eq(0).val();
				}


				$.ajax({
					url: settings.postUrl,
					type: 'POST',
					data: JSON.stringify(data),
					dataType: 'json',
					contentType: 'application/json',
					context: $this
				}).done( function (data) {

					$settings = $(this).data('settings');
					$(settings.loaderSelector).hide();
					$(settings.messageAreaSelector)
						.text(settings.successMessage)
						.addClass('success');
				});
			}
			return false;
		});

	});
};

$(document).ready(function(){
	$('#newsletter-subscription').newsletterSignUp({
		nameFieldSelector: '',
		emailFieldSelector: '#email',
		submitButtonSelector: '.button-newsletter',
		successMessage: 'Thank you for your interest in Elphas Academy. We have received your submission.'
	});
});