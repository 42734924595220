var allUsersProgressGraphSetup = {
	meanData: [],
	groupColor: {
		'1': '#d11141',
		'2': '#00b159',
		'3': '#00aedb',
		'4': '#f37735',
		'5': '#ffc425',
		'6': '#1e7145',
		'7': '#603cba',
		'8': '#2b5797',
		'-1': '#787878',
	},
	chartOptions: {
		scales: {
			yAxes: [{
				scaleLabel: {
					display: true,
					labelString: 'ELO rating'
				}
			}],
			xAxes: [{
				scaleLabel: {
					display: true,
					labelString: 'Task count'
				},
			}],
		},
		legend: {
			display: true,
			position: 'bottom',
			labels: {
				usePointStyle: true
			},
		},
		title: {
			display: true,
			text: '',
			fontSize: 22,
			fontFamily: 'Lato'
		}
	},
	userDatasetOptions: {
		borderWidth: '1',
	},
	levelMeanDatasetOptions: {
		borderWidth: '1',
		fill: false,
		showLine: true,
	},

	init: function (progress, options) {
		if (options) {
			if ("groupColor" in options) {
				this.groupColor = options.groupColor;
			}
			if ("skillColor" in options) {
				this.skillColor = options.skillColor;
			}
		}
		this.meanData = this.calculateLevelMeans(progress);
		this.allUsersProgressChart(progress);

	},
	calculateLevelMeans: function (progress) {
		// calculate total elo value per task for every level
		var meanTotal = [];
		for (var eloType in progress) {
			var users = progress[eloType]['users'];
			meanTotal[eloType] = [];
			for (var userIndex in users) {
				var user = users[userIndex];
				var userLevel = user.level;
				if (meanTotal[eloType][userLevel] === undefined) {
					meanTotal[eloType][userLevel] = [];
				}
				for (var taskIndex in user.logs) {
					if (meanTotal[eloType][userLevel][taskIndex] === undefined) {
						meanTotal[eloType][userLevel][taskIndex] = [];
						meanTotal[eloType][userLevel][taskIndex]['totalRating'] = 0;
						meanTotal[eloType][userLevel][taskIndex]['totalratingCount'] = 0;
						meanTotal[eloType][userLevel][taskIndex]['taskCount'] = 0;
					}
					var logItem = user.logs[taskIndex];
					var rating = logItem.rating;
					var taskCount = logItem.taskcount;
					meanTotal[eloType][userLevel][taskIndex]['totalRating'] += rating;
					meanTotal[eloType][userLevel][taskIndex]['taskCount'] = taskCount;
					meanTotal[eloType][userLevel][taskIndex]['totalratingCount']++;
				}
			}
		}
		// calculate mean elo value per task for every level in skill
		var meanData = [];
		for (var eloType in meanTotal) {
			meanData[eloType] = [];
			for (var userLevel in meanTotal[eloType]) {
				meanData[eloType][userLevel] = [];
				for (var taskIndex in meanTotal[eloType][userLevel]) {
					var totalRating = meanTotal[eloType][userLevel][taskIndex]['totalRating'];
					var totalRatingCount = meanTotal[eloType][userLevel][taskIndex]['totalratingCount'];
					var taskCount = meanTotal[eloType][userLevel][taskIndex]['taskCount'];
					var taskRatingMean = (totalRating / totalRatingCount);
					meanData[eloType][userLevel].push({'x': taskCount, 'y': taskRatingMean});
				}
			}
		}
		return meanData;
	},
	allUsersProgressChart: function (progress) {
		// add chart for each skill with elo level per taskindex for all users and a groep mean
		for (var elotype in progress) {
			var datasets = [];

			//add dataset for every user with elo rating per taskindex
			for (var user in progress[elotype]['users']) {
				var data = [];
				for (var i = 0; i < progress[elotype]['users'][user]['logs'].length; i++) {
					var rating = progress[elotype]['users'][user]['logs'][i].rating;
					var taskcount = progress[elotype]['users'][user]['logs'][i].taskcount;
					data.push({'x': taskcount, 'y': rating});
				}
				datasets.push(
					{
						borderWidth: this.userDatasetOptions.borderWidth,
						borderColor: this.groupColor[progress[elotype]['users'][user]['level']],
						label: 'user ' + progress[elotype]['users'][user]['id'],
						data: data,
					}
				);
			}

			// add dataset for mean elo rating per task for all levels.
			for (skillLevel in this.meanData[progress[elotype]['type']]) {
				data = [];
				for (taskIndex in this.meanData[progress[elotype]['type']][skillLevel]) {
					var rating = this.meanData[progress[elotype]['type']][skillLevel][taskIndex].y;
					var taskcount = this.meanData[progress[elotype]['type']][skillLevel][taskIndex].x;
					data.push({'x': taskcount, 'y': rating});
				}
				datasets.push(
					{
						borderWidth: this.levelMeanDatasetOptions.borderWidth,
						fill: this.levelMeanDatasetOptions.fill,
						showLine: this.levelMeanDatasetOptions.showLine,
						borderColor: this.groupColor[skillLevel],
						label: 'Mean groep ' + skillLevel,
						data: data,

					}
				);
			}
			// add chart title
			this.chartOptions.title.text = 'Skill: ' + progress[elotype]['type'];
			var allUsersProgressCanvas = document.getElementById("chart-" + progress[elotype]['type']).getContext('2d');
			var dailyAllUsers = new Chart(allUsersProgressCanvas, {
				type: 'scatter',
				data: {
					datasets: datasets,
				},
				options: this.chartOptions
			});
		}
	},
};

var allGroupedUsersProgressGraphSetup = {
	meanData: [],
	groupColor: {
		'1': '#d11141',
		'2': '#00b159',
		'3': '#00aedb',
		'4': '#f37735',
		'5': '#ffc425',
		'6': '#1e7145',
		'7': '#603cba',
		'8': '#2b5797',
		'-1': '#787878',
	},
	chartOptions: {
		scales: {
			yAxes: [{
				scaleLabel: {
					display: true,
					labelString: 'ELO rating'
				}
			}],
			xAxes: [{
				scaleLabel: {
					display: true,
					labelString: 'Task count'
				},
			}],
		},
		legend: {
			display: true,
			position: 'bottom',
			labels: {
				usePointStyle: true
			},
		},
		title: {
			display: true,
			text: '',
			fontSize: 22,
			fontFamily: 'Lato'
		}
	},
	userDatasetOptions: {
		borderWidth: '1',
	},
	levelMeanDatasetOptions: {
		borderWidth: '1',
		fill: false,
		showLine: true,
	},

	init: function (progress, options) {
		if (options) {
			if ("groupColor" in options) {
				this.groupColor = options.groupColor;
			}
			if ("skillColor" in options) {
				this.skillColor = options.skillColor;
			}
		}
		this.allGroupedUsersProgressChart(progress);
	},
	allGroupedUsersProgressChart: function (progress) {

		// add chart for each skill with elo level per taskindex for all users and a groep mean
		for (var level in progress) {
			// console.log('level', progress[level]);
			for (var elotype in progress[level]) {
				//add dataset for every user with elo rating per taskindex
				var datasets = [];
				for (var user in progress[level][elotype]['users']) {
					// console.log('user', user);
					var data = [];
					for (var i = 0; i < progress[level][elotype]['users'][user]['logs'].length; i++) {
						var rating = progress[level][elotype]['users'][user]['logs'][i].rating;
						var taskcount = progress[level][elotype]['users'][user]['logs'][i].taskcount;
						data.push({'x': taskcount, 'y': rating});
					}
					datasets.push(
						{
							borderWidth: this.userDatasetOptions.borderWidth,
							borderColor: this.groupColor[progress[level][elotype]['users'][user]['level']],
							label: 'user ' + progress[level][elotype]['users'][user]['id'],
							data: data,
						}
					);
				}

				// add chart title
				this.chartOptions.title.text = 'Skill: ' + progress[level][elotype]['type'];
				var allUsersProgressCanvas = document.getElementById("chart-" + level + "-" + progress[level][elotype]['type']).getContext('2d');
				dailyAllUsers = new Chart(allUsersProgressCanvas, {
					type: 'scatter',
					data: {
						datasets: datasets,
					},
					options: this.chartOptions
				});
			}

		}
	},

};

var userDailyProgressGraphSetup = {
	meanData: [],
	skillColor: {
		'arithmetic': '#1555BB',
		'subtraction': '#85c30f',
		'addition': '#ce2b00',
		'division': '#990271',
		'multiplication': '#00222d'
	},
	chartOptions: {
		scales: {
			yAxes: [{
				ticks: {
					display: true,
				},
				scaleLabel: {
					display: true,
					labelString: 'ELO rating'
				}
			}],
			xAxes: [{
				scaleLabel: {
					display: true,
					labelString: 'Date'
				},
				type: 'time',
				time: {
					unit: 'day'
				}
			}],
		},
		legend: {
			display: true,
			position: 'bottom',
			labels: {
				usePointStyle: true
			},
		},
		title: {
			display: true,
			text: 'your progress of all time',
			fontSize: 22,
			fontFamily: 'Lato'
		}
	},
	datasetOptions: {
		borderWidth: '1',
		fill: false,
		showLine: true,
	},

	init: function (progress, options) {
		if (options) {
			if ("groupColor" in options) {
				this.groupColor = options.groupColor;
			}
			if ("skillColor" in options) {
				this.skillColor = options.skillColor;
			}
		}
		this.userDailyProgressChart(progress);

	},
	userDailyProgressChart: function (progress) {
		// chart with last elo level per day for every skill for current user
		var datasets = [];

		for (var i = 0; i < progress.length; i++) {
			var data = [];
			for (var j = 0; j < progress[i]['logs'].length; j++) {
				var rating = progress[i]['logs'][j].rating;
				var date = moment(progress[i]['logs'][j].created_at);
				data.push({'x': date, 'y': rating});
			}
			datasets.push(
				{
					borderWidth: this.datasetOptions.borderWidth,
					fill: this.datasetOptions.fill,
					showLine: this.datasetOptions.showLine,
					borderColor: this.skillColor[progress[i]['type']],
					label: progress[i]['type'],
					data: data,
				}
			);
		}
		var ctxUserDailyProgress = document.getElementById("chart-user-daily-progress").getContext('2d');
		var chartUserDailyProgress = new Chart(ctxUserDailyProgress, {
			type: 'scatter',
			data: {
				datasets: datasets,
			},
			options: this.chartOptions
		});
	}
};

allUsersProgressGraphSetup.init(allUsersProgress);
allGroupedUsersProgressGraphSetup.init(allGroupedUsersProgress);
userDailyProgressGraphSetup.init(userDailyProgress);