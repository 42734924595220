$(document).ready(function() {

	$('.landing-page-navigation').find("#nelson-protocul-button").click(function() {
		$('html,body').animate({
				scrollTop: $("#nelson-protocol").offset().top},
			'slow');
	});

	$('.landing-page-navigation').find("#why-aletta-button").click(function() {
		$('html,body').animate({
				scrollTop: $("#why-aletta-block").parent().offset().top},
			'slow');
	});

	$('#show-more-button').click(function() {
		$('html,body').animate({
					scrollTop: $("#scrollto").offset().top},
				'slow');
	});

});
