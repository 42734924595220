$(document).ready(function() {



	$('.button-auth-submit').on('click', function(e){
		e.preventDefault();
		$(this).parents('form').each(function(){
			$(this).trigger('submit');
		});
	});

	$('.login-with-enter').on('keypress', function(e) {
		if (e.keyCode == 13) {
			e.preventDefault();
			$(this).parents('form').each(function () {
				$(this).trigger('submit');
			});
		}
	});

});
