
// @todo this is super dirty and needs to have a whole lot of cleaning, just as a test
$(document).ready(function() {
	console.log('marker document ready');
	//
	// $('p').on('mouseup', showMarker);
	// $(document).on('keyup', showMarker);
	//
	// $(document).on('mousedown', hideMarker);
	// $(document).on('keydown', hideMarker);
});

var markerEl, markerId = "sel_" + new Date().getTime() + "_" + Math.random().toString().substr(2);
var selectionEl;
var markerTextChar = "\ufeff";
var markerTextCharEntity = "&#xfeff;";
var sel, range;

function getSelectedText() {
	var text = "";
	if (typeof window.getSelection != "undefined") {
		text = window.getSelection().toString();
	} else if (typeof document.selection != "undefined" && document.selection.type == "Text") {
		text = document.selection.createRange().text;
	}
	return text;
}

function showMarker(event) {
	event.stopPropagation();
	hideMarker();
	$(this).filter( function(index){

		var selectedText = getSelectedText();

		if (selectedText) {
			//alert("Got selected text " + selectedText);
			console.log(selectedText);
			if(selectionEl){
				selectionEl.style.visibility = "visible";
			}
			markSelection();
		}

	});
}

function hideMarker() {
	if(selectionEl){
		selectionEl.style.visibility = "hidden";
	}
}

function markSelection() {


	if (document.selection && document.selection.createRange) {
		// Clone the TextRange and collapse
		range = document.selection.createRange().duplicate();
		range.collapse(false);


		// Create the marker element containing a single invisible character by creating literal HTML and insert it
		range.pasteHTML('<span id="' + markerId + '" style="position: relative;">' + markerTextCharEntity + '</span>');
		markerEl = document.getElementById(markerId);
	} else if (window.getSelection) {
		sel = window.getSelection();

		if (sel.getRangeAt) {
			range = sel.getRangeAt(0).cloneRange();
		} else {
			// Older WebKit doesn't have getRangeAt
			range.setStart(sel.anchorNode, sel.anchorOffset);
			range.setEnd(sel.focusNode, sel.focusOffset);

			// Handle the case when the selection was selected backwards (from the end to the start in the
			// document)
			if (range.collapsed !== sel.isCollapsed) {
				range.setStart(sel.focusNode, sel.focusOffset);
				range.setEnd(sel.anchorNode, sel.anchorOffset);
			}
		}

		range.collapse(false);

		// Create the marker element containing a single invisible character using DOM methods and insert it
		markerEl = document.createElement("span");
		markerEl.id = markerId;
		markerEl.appendChild( document.createTextNode(markerTextChar) );
		range.insertNode(markerEl);
	}

	if (markerEl) {
		// Lazily create element to be placed next to the selection
		if (!selectionEl) {
			selectionEl = document.createElement("div");
			selectionEl.style.border = "none";
			selectionEl.style.borderRadius = "4px";
			selectionEl.style.padding = "5px";
			selectionEl.style.color = "white";
			selectionEl.style.backgroundColor = "#373737";
			selectionEl.innerHTML = "selection";
			selectionEl.style.position = "absolute";
			selectionEl.style.fontFamily = "Lato";

			document.body.appendChild(selectionEl);
		}

		// Find markerEl position http://www.quirksmode.org/js/findpos.html
		var obj = markerEl;
		var left = 0, top = 0;
		do {
			left += obj.offsetLeft;
			top += obj.offsetTop;
		} while (obj = obj.offsetParent);


		top -= 10;
		// Move the button into place.
		// Substitute your jQuery stuff in here
		selectionEl.style.left = left + "px";
		selectionEl.style.top = top + "px";

		markerEl.parentNode.removeChild(markerEl);
	}

};