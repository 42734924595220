
function logActivity(type, id, activity, meta, courseCode) {

	console.log('logActivity: ' + type +' ' +id +' ' + activity + ' ' + courseCode);
	var url = window.location.pathname;

	return axios.post('/api/log',
			{
				type:type,
				id:id,
				activity:activity,
				meta: meta,
				courseCode: courseCode,
				path: url
			})
			.then(function (response) {
				console.log("success: " + response);

				return true;
			})
			.catch(function (error) {
				console.log("error:" + error);
				return true;
			});

	}
window.logActivity = logActivity;
// function createMetaData(question, answers, user_answer) {
// 	// var obj = `{
//      //       "question" : "`+ question +`",
//      //       "answers" : "`+ answers +`",
//      //       "user_answer" : "`+user_answer+`",
//      //       }`;
//
// 	var obj = new Object();
// 	obj.question = question;
// 	obj.answers  = answers;
// 	obj.user_answer = user_answer;
// 	var metaDataJson = JSON.stringify(obj);
//
// 	return metaDataJson;
// }

