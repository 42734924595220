$(document).ready(function() {
	//todo do this only on the cases overview template, and not on every page
	//caseOverviewHandeler.loadCases();

	$('.save-case').on('click', caseOverviewHandeler.saveFinishedCase);

});

var caseOverviewHandeler = {

	//todo Loop through all cases and present them.

	loadCases:function(){

		console.log('dit moet anders');
		var url = "/lesson/radia-7/1/cases";

		$.get( url, function( data ) {
			console.log(data);

		});
	},

	saveFinishedCase: function(e) {
		//console.log("postanswer");

		var postAnswerUrl = '/cases/case-finish';

		e.preventDefault();
		var formData = {
			_token: $(this).data("_token")
		};

		caseHandeler.postData(formData, postAnswerUrl, function(data){
			//zet tekst op goede locatie
			console.log("saved case")

		} ,'json');
	},

	postData: function(data, url, callback, dataType) {
		if (!dataType) {
			dataType = 'HTML';
		}
		$.ajax({
			url: url,
			data: data,
			method: 'POST',
			xhrFields: {
				withCredentials: true
			},
			dataType: dataType
		}).done(callback);
	},


}
window.caseOverviewHandeler = caseOverviewHandeler;