
var nodule = {

	scene: null,
	loader: null,
	renderer: null,
	camera: null,
	cameras: {

	},
	autoSelectCurrent: false,

	//@todo triggeren van segmentationMode setting + handelen ervan function is er al
	segmentatioMode: false,

	// cameraTransversal: null,
	// cameraFrontal: null,
	// cameraSagital: null,
	noduleMeshes:[],
	$screen: null,
	screen: null,
	objectSettings: {},
	defaultSphereOpacity: 0.6,
	defaultMeshOpacity: 1,
	preselectNodules: false,

	noduleObjects:[],

	init: function($screen, screen) {

		this.$screen = $screen;
		this.screen = screen;
		this.setupScene();
		this.setupLoader();

		if (!this.setupRenderer($screen)) {
			return;
		}

		this.setupNodulesAndCameraForScanDate(this.screen.scanDate);
		var scanDate = this.screen.scanDate;
		this.render();

		$screen.on('slider-change', this.getViewUpdateHandler(this));


		$('.scanner').on('nodule-removed',  function(self) {
			return function(event){
				self.changeNoduleOpacity(event.noduleId, 0);
			};
		}(this));

		$('.scanner').on('change-nodule-opacity',function(self) {
			return function(event){
				self.changeNoduleOpacity(event.noduleId, event.opacity);
			};
		}(this));

		$('.scanner').on('sneakPeek',function(self) {
			return function(event){
				self.toggleNoduleSneakPeek(event.active);
			};
		}(this));


		this.$screen.on('scanDate-changed', function(self){ return function() {
			self.setupScene();
			self.setupNodulesAndCameraForScanDate(self.screen.scanDate);
		}}(this));

		$('.scanner').on('nodule-found', function(self) {
			return function(event){
				console.log('event: ', event);
				self.changeNoduleOpacity(event.noduleId, 1, event.scanDate);
			};
		}(this));

		this.matchDimensions();
		if (this.preselectNodules) {
			this.selectAllNodules(this);
		}

	},

	setupNodulesAndCameraForScanDate: function(scanDate) {
		for (var i = 0 ; i < this.objectSettings[scanDate].noduleinfo.length; i++){


			//this.loadNoduleToScene(this.objectSettings[scanDate].nodulePath[i], this.scene, this.objectSettings.noduleColor[i], i);

			this.loadNoduleToScene(this.objectSettings[scanDate].noduleinfo[i], this.scene);
		}

		this.setupCamera(this.scene);


	},
	setOrientation: function(orientation) {
		this.orientation = orientation;
	},
	hideNodule: function(noduleId) {

		for (var i = 0; i < this.noduleMeshes.length; i++) {
			if (this.noduleMeshes[i].noduleId == noduleId) {
				this.noduleMeshes[i].mesh.material.opacity = 0;
				this.noduleMeshes[i].mesh.geometry.attributes.position.needsUpdate = true;
				this.renderer.render(this.scene, this.camera);

			}
		}
	},

	/**
	 * Shows all nodules
	 */
	toggleNoduleSneakPeek: function (sneakPeekMode) {

		for (var i = 0; i < this.noduleMeshes.length; i++) {
			if (this.noduleMeshes[i].sphere.material.opacity < 0.3 && this.noduleMeshes[i].mesh.material.opacity < 0.3) {

				if (sneakPeekMode) {
					this.noduleMeshes[i].sphere.material.opacity = 0.2;
					console.log('sneakPeek on');
				} else {
					this.noduleMeshes[i].sphere.material.opacity = 0;
					console.log('sneakPeek off');
				}
				this.noduleMeshes[i].mesh.geometry.attributes.position.needsUpdate = true;
				this.renderer.render(this.scene, this.camera);
			}
		}



	},

	/**
	 * sets segmentation mode and check if its a change
	 *
	 * @param mode bool
	 * @return void
	 */
	setSegmentationMode: function(mode) {
		if (this.segmentatioMode == mode){
			return;
		}
		this.segmentatioMode = mode;
		this.changeSegmentationMode(mode);
	},

	/**
	 *
	 * @param bool segmentationMode
	 */
	changeSegmentationMode: function(segmentationMode) {
		var needsRerendering = false;
		// console.log( this.noduleMeshes);
		for (var i = 0; i < this.noduleMeshes.length; i++) {

			var needsUpdate = false;
			if (this.noduleMeshes[i].sphere.material.opacity > .3 && segmentationMode == true) {
				this.noduleMeshes[i].mesh.material.opacity = nodule.defaultMeshOpacity;
				this.noduleMeshes[i].sphere.material.opacity = 0;
			}

			if (this.noduleMeshes[i].mesh.material.opacity > 0 && segmentationMode == false) {
				this.noduleMeshes[i].mesh.material.opacity = 0;
				this.noduleMeshes[i].sphere.material.opacity = nodule.defaultSphereOpacity;
				// console.log('display sphere');

			}
				if (needsUpdate ){
				this.noduleMeshes[i].mesh.geometry.attributes.position.needsUpdate = true;
				needsRerendering = true;
			}
		}

		if (needsRerendering) {
			this.renderer.render(this.scene, this.camera);
		}
	},

	changeNoduleOpacity: function(noduleId, opacity, scanDate) {
		var testScanDate;

		for (var i = 0; i < this.noduleMeshes.length; i++) {
			if(scanDate == undefined || this.autoSelectCurrent) {
				testScanDate = this.noduleMeshes[i].scanDate;
			} else {
				testScanDate = scanDate;
			}
			if (this.noduleMeshes[i].noduleId == noduleId && this.noduleMeshes[i].scanDate == testScanDate) {

				if(this.segmentatioMode) {
					this.noduleMeshes[i].mesh.material.opacity = opacity;
					this.noduleMeshes[i].sphere.material.opacity = 0;
				} else {
					this.noduleMeshes[i].mesh.material.opacity = 0;
					this.noduleMeshes[i].sphere.material.opacity = opacity;
				}

				this.noduleMeshes[i].mesh.geometry.attributes.position.needsUpdate = true;
				this.renderer.render(this.scene, this.camera);

			}
		}
	},
	setupScene: function() {
		this.scene = new THREE.Scene();

		var directionalLight = new THREE.DirectionalLight(0xffeedd);
		directionalLight.position.set(1, 1, 1);
		this.scene.add(directionalLight);

		var directionalLight2 = new THREE.DirectionalLight(0xffeedd);
		directionalLight.position.set(-1, -1, -1);
		this.scene.add(directionalLight2);

		var ambient = new THREE.AmbientLight(0x101030);
		this.scene.add(ambient);
	},

	setupLoader: function() {
		var manager = new THREE.LoadingManager();
		manager.onProgress = function (item, loaded, total) {

			if (loaded ==  total) {
				$('.scanner').trigger('meshesLoaded');
				console.log('trigger meshesLoaded');
			}
		};

		this.loader = new THREE.STLLoader(manager);

	},
	setupRenderer: function($screen) {

		console.log('setup renderer');

		$screen.find('.canvascontainer').append('<div class="node-container">');
		var $container = $screen.find('.node-container');


		var webgl = ( function () {
			try {
				var canvas = document.createElement( 'canvas' ); return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );
			} catch ( e ) {
				return false;
			}
		} )();

		if (!webgl) {
			alert("Your graphics card does not seem to support WebGL.\r\nThe viewer will not be functional. A solution for you browser may be found here :\r\nhttps://superuser.com/questions/836832/how-can-i-enable-webgl-in-my-browser");

			// if you use chrome this error will occure when hardwhare acceleration is turned off
			throw new Error('WebGL support missing');
			return false;
		}

		this.renderer = new THREE.WebGLRenderer({ alpha: true });
		this.renderer.setPixelRatio(window.devicePixelRatio);

		var scanDate = this.screen.scanDate;
		this.renderer.setSize(this.objectSettings[scanDate].firstSlice.scanSize[0], this.objectSettings[scanDate].firstSlice.scanSize[1]);

		$container.append(this.renderer.domElement);
		var $canvas =  $container.find('canvas');

		$canvas.data('noduleobject',this);

		// select a nodule
		var getMouseClickHandler = function(self){
			return function (event) {
				var $canvas = $(this);
				var rectangle = event.target.getBoundingClientRect();

				var mousePos = {
					x: event.clientX - rectangle.left,
					y: event.clientY - rectangle.top
				};

				//

				var $canvasContainer = $canvas.parents('.canvascontainer');
				var zoomFactor = parseFloat($canvasContainer.css('zoom'));

				if (!zoomFactor){
					zoomFactor = parseFloat($canvasContainer.attr('data-zoomfactor'));
					if (!zoomFactor) {
						zoomFactor = 1;
					}
				}

				var zoomMouseX = event.clientX/zoomFactor - rectangle.left;
				var zoomMouseY = event.clientY/zoomFactor - rectangle.top;
				var ratio = {
					x:zoomMouseX/rectangle.width,
					y:zoomMouseY/rectangle.height
				};
				////
				console.log('muis klik',mousePos, zoomFactor, ratio, [event.clientX,event.clientY ]);



				// var ratioX = x/rectangle.width;
				// var ratioY = y/rectangle.height;
				var dicomCoordinates = self.mouseToDicomCoordinates(ratio.x, ratio.y, $canvas);

				var message = 'Mouse position: ' + mousePos.x + ',' + mousePos.y;
				self.processDicomClick(self, dicomCoordinates, $canvas);
			}
		};


		$canvas.on('click',	getMouseClickHandler(this));

		var getMatchDimFunc = function(nodule) {
			var self = nodule;
			return function (e) {
				self.matchDimensions();
			};
		};

		this.$screen.on('resize', getMatchDimFunc(this));
		return true;
	},

	/**
	 * Process the click
	 *
	 * @param self nodule object
	 * @param dicomCoordinates xyz in dicom coordinates
	 * @param $canvas nodules canvas
	 */
	processDicomClick: function(self, dicomCoordinates, $canvas) {
		var scanDate = self.screen.scanDate;
//		console.log('self:', self);
		for (var i = 0; i < self.noduleMeshes.length; i++) {
			var pickingRenderTarget = self.noduleMeshes[i].mesh;
			var box = new THREE.Box3().setFromObject(pickingRenderTarget);

			var clickPoint = new THREE.Vector3(dicomCoordinates[0], dicomCoordinates[1], dicomCoordinates[2]);
			// console.log(box,clickPoint);
			//console.log(i, box.min, box.max);

			if (box.containsPoint(clickPoint)) {

				if (this.segmentatioMode) {

					self.noduleMeshes[i].mesh.material.opacity = nodule.defaultMeshOpacity;
					//self.noduleMeshes[i].mesh.geometry.attributes.position.needsUpdate = true;
					self.noduleMeshes[i].mesh.geometry.colorsNeedUpdate = true;

				} else {

					self.noduleMeshes[i].sphere.material.opacity = nodule.defaultSphereOpacity;
					//self.noduleMeshes[i].sphere.geometry.attributes.position.needsUpdate = true;
					self.noduleMeshes[i].sphere.geometry.colorsNeedUpdate = true;
				}
				self.renderer.render(self.scene, self.camera);

				var capturedNodule;
				self.objectSettings[scanDate].noduleinfo.forEach(function (value) {
					if (value.id == self.noduleMeshes[i].noduleId) {
						capturedNodule = value;
					}
				});

				self.sendFoundEvent(self, capturedNodule, box, 0, scanDate, self.noduleMeshes[i]);
				self.flashNoduleInfo(self, capturedNodule)

			}
		}
	},
	flashNoduleInfo(self, nodule) {
		console.log('flash nodule info', nodule)

		var noduleLabel = 'Nodule'
		var $noduleInPanel  = $('li.nodule-id-' + nodule.id);
		if ($noduleInPanel.length > 0) {
			noduleLabel = $noduleInPanel.text();
		}

		$('#nodule-info-flash').remove();
		var $flashobject = self.$screen.append('<div id="nodule-info-flash">' + noduleLabel + '<br>' + nodule.details.volume_correct + ' mm<sup>3</sup></div>');
		setTimeout(function() {
			$('#nodule-info-flash').remove();
		}, 5000);
		// console.log('noduleLabel',noduleLabel + '<br>' + nodule.details.volume_correct)

	},
	/**
	 * Alle prior bevindingen tonen
	 */
	selectAllNodules: function(self) {

		// console.log('selectallnodules self: ', self);


		var scanDate = self.screen.scanDate;

		var delayedCallback = function() {
			console.log('selectAllNodules', self.noduleMeshes);

			// console.log(self.noduleMeshes[0]);
			// console.log(self.noduleMeshes.length);


			var countNoduleMeshes = self.noduleMeshes.length;
			for (var i = 0; i < countNoduleMeshes; i++) {
				var pickingRenderTarget = self.noduleMeshes[i];

				if(pickingRenderTarget.scanDate != scanDate) {
					console.log('skipping pickingRenderTarget', pickingRenderTarget, pickingRenderTarget.noduleId );
					continue;
				}

				console.log('processing pickingRenderTarget', pickingRenderTarget, pickingRenderTarget.noduleId );

				var box = new THREE.Box3().setFromObject(pickingRenderTarget.mesh);


				var capturedNodule;
				console.log('objectSettings', self.objectSettings, scanDate, self.objectSettings[scanDate].noduleinfo);
				var countNoduleInfo = self.objectSettings[scanDate].noduleinfo.length;
				for (var j = 0; j < countNoduleInfo; j++) {
					var value = self.objectSettings[scanDate].noduleinfo[j];
					console.log('info', value, pickingRenderTarget.noduleId);
					if (value.id == pickingRenderTarget.noduleId) {
						capturedNodule = value;
					}
				}



				if (capturedNodule) {
					console.log('capturedNodule',capturedNodule);
					self.sendFoundEvent(self, capturedNodule, box, 0, scanDate, pickingRenderTarget);
				}
			}
		};

		var retryThis = function(availableCallback) {
			var retryFunction = function () {
				if (self.noduleMeshes.length > 0 && self.objectSettings[scanDate]) {
					availableCallback();
				} else {
					console.log('.');
					setTimeout(retryFunction, 2000);
				}
			};
			retryFunction();
		};
		retryThis(delayedCallback);


	},

	/**
	 *
	 * @param self
	 * @param capturedNodule
	 * @param box
	 * @param sliceIndex
	 * @param scanDate
	 */
	sendFoundEvent: function(self, capturedNodule, box, sliceIndex, scanDate, noduleMesh) {

		if (!scanDate) {
			console.log('scanDate missing');
			return;
		}

		console.log('scanDate found');
		var details = capturedNodule.details;

		var zDistPerSlice = (self.objectSettings[scanDate].lastSlice.imagePositionPatient[2] - self.objectSettings[scanDate].firstSlice.imagePositionPatient[2]) / (self.objectSettings[scanDate].lastSlice.sliceIndex);
		var zSliceTop = Math.round((box.min.z - self.objectSettings[scanDate].firstSlice.imagePositionPatient[2]) / zDistPerSlice);
		var zSliceBottom = Math.round((box.max.z - self.objectSettings[scanDate].firstSlice.imagePositionPatient[2]) / zDistPerSlice);

		if (sliceIndex == 0 ){
			sliceIndex = Math.ceil((zSliceTop + zSliceBottom)/2);
		}

		// add nodule color
		var foundColor = '0x888888'; //grijs
		for (var i=0,len=self.objectSettings[scanDate]['noduleinfo'].length; i < len; i++) {
			var testNodule = self.objectSettings[scanDate]['noduleinfo'][i];
			if (testNodule.id == details.nodeIndex) {
				foundColor = testNodule.color
			}
		}

		var eventDetails = $.extend({}, details, {
			type: 'nodule-found',
			noduleId: noduleMesh.noduleId,
			boundingBox: {min: box.min, max: box.max},
			zSliceTop: zSliceTop,
			zSliceBottom: zSliceBottom,
			sliceIndex: sliceIndex,
			scanDate: scanDate,
			color: foundColor
		});
		console.log(eventDetails);

		$('.scanner').trigger(eventDetails);

	},

	// getMouseClickHandler: function(self){
	// 	return function (event) {
	//
	// 		var rect = event.target.getBoundingClientRect();
	// 		var mousePos = {
	// 			x: event.clientX - rect.left,
	// 			y: event.clientY - rect.top
	// 		};
	//
	// 		var $canvas = $(this);
	// 	}
	// },
	mouseToDicomCoordinates: function(ratioX, ratioY, $canvas) {

		var dicomX, dicomY, dicomZ;
		if ($canvas.attr('data-orientation') == 'transversal') {

			dicomZ = parseFloat($canvas.attr('data-z'));

			var canvasX, canvasX0;
			canvasX0 = parseFloat($canvas.attr('data-x0'));
			canvasX = parseFloat($canvas.attr('data-x'));
			dicomX = (canvasX - canvasX0)*ratioX + canvasX0;

			var canvasY, canvasY0;
			canvasY0 = parseFloat($canvas.attr('data-y0'));
			canvasY =  parseFloat($canvas.attr('data-y'));

			dicomY = (canvasY - canvasY0)*ratioY + canvasY0;

		} else if ($canvas.attr('data-orientation') == 'frontal') {

			dicomY = parseFloat($canvas.attr('data-y'));

			var canvasX, canvasX0;
			canvasX0 = parseFloat($canvas.attr('data-x0'));
			canvasX = parseFloat($canvas.attr('data-x'));
			dicomX = (canvasX - canvasX0)*ratioX + canvasX0;

			var canvasZ, canvasZ0;
			canvasZ0 = parseFloat($canvas.attr('data-z0'));
			canvasZ = parseFloat($canvas.attr('data-z'));
			dicomZ = (canvasZ - canvasZ0)*ratioY + canvasZ0;

		} else if ($canvas.attr('data-orientation') == 'sagital') {

			dicomX = parseFloat($canvas.attr('data-x'));

			var canvasY, canvasY0;
			canvasY0 = parseFloat($canvas.attr('data-y0'));
			canvasY =  parseFloat($canvas.attr('data-y'));
			dicomY = (canvasY - canvasY0)*ratioX + canvasY0;

			var canvasZ, canvasZ0;
			canvasZ0 = parseFloat($canvas.attr('data-z0'));
			canvasZ = parseFloat($canvas.attr('data-z'));
			dicomZ = (canvasZ - canvasZ0)*ratioY + canvasZ0;
		}
		return [dicomX,dicomY, dicomZ];
	},

	/**
	 * canvas sizes match containing screen
	 */
	matchDimensions: function() {

		var $dicomCanvas = this.$screen.find('.slice');
		var $canvas =  this.$screen.find('.node-container canvas');

		if ($canvas.width() ==  $dicomCanvas.width() && $canvas.height() ==  $dicomCanvas.height() ) {
			return;
		}
		var $screen = $dicomCanvas.parent('.screen');
		var width = $screen.width();
		var height = $screen.height();
		$canvas.width(width).height(height);
		$dicomCanvas.width(width).height(height);
	},

	setupCamera: function(scene){

		var scanDate = this.screen.scanDate;
		var settings = this.objectSettings[scanDate];

		var xStart = settings.firstSlice.imagePositionPatient[0]  ;
		var xEnd = xStart + (settings.firstSlice.pixelSpacing[0] * settings.firstSlice.scanSize[0]);

		var yStart = settings.firstSlice.imagePositionPatient[1];
		var yEnd = yStart + (settings.firstSlice.pixelSpacing[1] * settings.firstSlice.scanSize[1]);

		var zStart = settings.firstSlice.imagePositionPatient[2];
		var zEnd =  settings.lastSlice.imagePositionPatient[2];

		this.cameras[scanDate] = {transversal:null, frontal: null, sagital: null};
		// console.log(xStart, xEnd, yStart, yEnd, zStart, zEnd);
		var cameraTransversal = new THREE.OrthographicCamera(xEnd, xStart, yStart, yEnd, zStart, zEnd);
		cameraTransversal.position.set(-settings.cameraOffset.transversal[0], -settings.cameraOffset.transversal[1], 0);
		cameraTransversal.up = new THREE.Vector3(0,1,0);
		cameraTransversal.lookAt(new THREE.Vector3(-settings.cameraOffset.transversal[0], -settings.cameraOffset.transversal[1],1));
		this.cameras[scanDate].transversal = cameraTransversal;

		var cameraFrontal = new THREE.OrthographicCamera( xStart, xEnd,  zStart, zEnd, yStart, yEnd);
		cameraFrontal.position.set(-settings.cameraOffset.frontal[0], -settings.cameraOffset.frontal[1], 0);
		cameraFrontal.up = new THREE.Vector3(0,0,1);
		cameraFrontal.lookAt(new THREE.Vector3(-settings.cameraOffset.frontal[0], 1-settings.cameraOffset.frontal[1],0));
		this.cameras[scanDate].frontal = cameraFrontal;

		var cameraSagital = new THREE.OrthographicCamera(yEnd, yStart, zStart, zEnd, xStart, xEnd);
		cameraSagital.position.set(-settings.cameraOffset.sagital[0], -settings.cameraOffset.sagital[1], 0);
		cameraSagital.up = new THREE.Vector3(0, 0, 1);
		cameraSagital.lookAt(new THREE.Vector3(-1 -settings.cameraOffset.sagital[0], -settings.cameraOffset.sagital[1], 0));
		this.cameras[scanDate].sagital = cameraSagital;



		this.camera = this.cameras[scanDate].transversal;
	},
	errorHandler: function (xhr) {
		console.log('error:');
		console.log(xhr);
	},
	progressHandler: function (xhr) {
		if (xhr.lengthComputable) {
			var percentComplete = xhr.loaded / xhr.total * 100;
			//console.log(Math.round(percentComplete, 2) + '% downloaded');
		}
	},
	loadNoduleToScene: function(noduleInfo, scene) {
		// loadNoduleToScene: function(nodulePath, scene, color, noduleId) {

		var nodulePath = noduleInfo.path;
		var color =  parseInt(noduleInfo.color);
		var noduleId = noduleInfo.id;

		console.log('noduleInfo in loadNoduleToScene: ', noduleInfo);
		// drop all meshes:
		// console.log('scene json:', scene.toJSON());

		// define material,
		var noduleColor = color;
		// load nodule to scene


		var nodules = this.noduleMeshes;
		var self = this;
		var currentNodule = noduleId;

		// console.log('draw nodule: ' +noduleId );
		this.loader.load(nodulePath, function (geometry) {
			// @todo opcity weer op 0 zetten
			var material = new THREE.MeshPhongMaterial( { color: noduleColor, side: THREE.DoubleSide, flatShading: THREE.SmoothShading, transparent: true, opacity: 0,
				//specular: 0xffffff,
				//shininess: 10,
				emissive: noduleColor

			} );

			//geometry.computeBoundingBox();
			geometry.computeVertexNormals();
			//var modifier = new THREE.SubdivisionModifier( 4 );

			var geometryFull = new THREE.Geometry().fromBufferGeometry( geometry );
			geometry = new THREE.BufferGeometry().fromGeometry( geometryFull );
			var scanDate = self.screen.scanDate;
			var settings = self.objectSettings[scanDate];

			var mesh = new THREE.Mesh(geometry, material);
			mesh.translateX(settings.noduleOffset[0]);
			mesh.translateY(settings.noduleOffset[1]);
			mesh.translateZ(settings.noduleOffset[2]);


			mesh.castShadow = true;
			mesh.receiveShadow = true;
			scene.add(mesh);
			//nodules.push({noduleId: currentNodule, mesh: mesh});

			geometry.computeBoundingSphere();
			var sfeer = geometry.boundingSphere;
			var sphereGeometry = new THREE.SphereBufferGeometry(sfeer.radius + 2, 64 ,64 );
			sphereGeometry.computeBoundingSphere();
			sphereGeometry.translate(sfeer.center.x + settings.noduleOffset[0], sfeer.center.y + settings.noduleOffset[1], sfeer.center.z + settings.noduleOffset[2]);
			var material = new THREE.MeshPhongMaterial( { color: noduleColor, side: THREE.DoubleSide, flatShading: THREE.SmoothShading, transparent: true, opacity: 0,
				//specular: 0xffffff,
				//shininess: 10,
				emissive: noduleColor

			} );
			//var material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
			var sphere = new THREE.Mesh( sphereGeometry, material );
			scene.add( sphere );

			nodules.push({scanDate: noduleInfo.details.scanDate, noduleId: currentNodule, mesh: mesh, sphere: sphere});

		}, this.progressHandler, this.errorHandler);
	},

	getViewUpdateHandler: function(noduleObject){
		var self = noduleObject;
		return function(slider, data) {
			self.render(-1*data.value);
		}
	},
	render: function(slideNr) {
		this.setCameraPositionForSliceAndOrientation(slideNr);
		this.renderer.render(this.scene, this.camera);


	},
	setCameraPositionForSliceAndOrientation: function(slideNr) {

		var scanDate = this.screen.scanDate;
		var settings = this.objectSettings[scanDate];

		if (!this.cameras[scanDate]) {
			this.setupCamera(this.scene);
			settings = this.objectSettings[scanDate];
			// console.log(this.cameras[scanDate]);
		}

		var orientation = this.screen.orientation;
		var nodeThickness = 3;

		var sliceCount = this.screen.slices.length;
		console.log('sliceCount:' + this.screen.slices.length);
		console.log('slicecount:' + sliceCount + ' date: ' + scanDate);
		var range;
		if (orientation == 'transversal') {
			this.camera = this.cameras[scanDate][orientation];
			console.log('slidenr' + slideNr);
			var cameraStartZ = settings.firstSlice.imagePositionPatient[2];
			var cameraEndZ = settings.lastSlice.imagePositionPatient[2];
			range = this.rangeCalculator(cameraStartZ, cameraEndZ, sliceCount, slideNr, nodeThickness);

			this.screen.$screen.find('.node-container canvas')
				.attr('data-x0', -1*this.camera.left)
				.attr('data-y', this.camera.bottom)
				.attr('data-x', -1*this.camera.right)
				.attr('data-y0', this.camera.top)
				.attr('data-z', (range[0] + range[1])/2)
				.attr('data-z0', cameraStartZ)
				.attr('data-sliceNr', slideNr)
				.attr('data-orientation','transversal' );

		} else if (orientation == 'frontal'){
			this.camera = this.cameras[scanDate][orientation];

			var yBottom = settings.firstSlice.imagePositionPatient[1] + (settings.firstSlice.pixelSpacing[1] * settings.firstSlice.scanSize[1]);
			var yTop = settings.firstSlice.imagePositionPatient[1];

			range = this.rangeCalculator(yTop, yBottom,  sliceCount, slideNr, nodeThickness);

			this.screen.$screen.find('.node-container canvas')
				.attr('data-x0', -1*this.camera.right )
				.attr('data-y', (range[0] + range[1])/2)
				.attr('data-y0', yTop)
				.attr('data-x',-1*this.camera.left)
				.attr('data-z', this.camera.bottom)
				.attr('data-z0', this.camera.top)
				.attr('data-sliceNr', slideNr)
				.attr('data-orientation','frontal' );

		} else if (orientation == 'sagital') {

			this.camera = this.cameras[scanDate][orientation];

			var xRight = settings.firstSlice.imagePositionPatient[0] + (settings.firstSlice.pixelSpacing[0] * settings.firstSlice.scanSize[0]);
			var xLeft = settings.firstSlice.imagePositionPatient[0];
			range = this.rangeCalculator(xRight, xLeft,  sliceCount, slideNr, nodeThickness);

			this.screen.$screen.find('.node-container canvas')
				.attr('data-x', -(range[0] + range[1])/2)
				.attr('data-x0', -xLeft)
				.attr('data-y', this.camera.right)
				.attr('data-y0', this.camera.left)
				.attr('data-z', this.camera.bottom)
				.attr('data-z0', this.camera.top)
				.attr('data-sliceNr', slideNr)
				.attr('data-orientation','sagital');
		}

		this.camera.near = range[0];
		this.camera.far = range[1];
		this.camera.updateProjectionMatrix();
	},
	rangeCalculator: function( start, end, count, index, width) {
		var sliceRatio = (end-start) / count;
		var center = start + (sliceRatio * index);
		var halfThicknessRange = (width * sliceRatio / 2);
		return [center-halfThicknessRange, center+halfThicknessRange];
	}

}
window.nodule = nodule;

// @todo: deze intersection berekening wordt nog niet gebuikt, maar is mischien later wel handig.
var intersection = {
	drawIntersectionPoints: function (object, plane, targetScene) {

		var setPointOfIntersection = function (line, plane) {
			pointOfIntersection = plane.intersectLine(line);
			if (pointOfIntersection) {
				pointsOfIntersection.vertices.push(pointOfIntersection.clone());
			}
		}

		var pointsOfIntersection = new THREE.Geometry();

		var a = new THREE.Vector3(),
			b = new THREE.Vector3(),
			c = new THREE.Vector3();
		var planePointA = new THREE.Vector3(),
			planePointB = new THREE.Vector3(),
			planePointC = new THREE.Vector3();
		var lineAB = new THREE.Line3(),
			lineBC = new THREE.Line3(),
			lineCA = new THREE.Line3();

		var mathPlane = new THREE.Plane();


		object.geometry.faces.forEach(function (face) {
			object.localToWorld(a.copy(object.geometry.vertices[face.a]));
			object.localToWorld(b.copy(object.geometry.vertices[face.b]));
			object.localToWorld(c.copy(object.geometry.vertices[face.c]));
			lineAB = new THREE.Line3(a, b);
			lineBC = new THREE.Line3(b, c);
			lineCA = new THREE.Line3(c, a);
			this.setPointOfIntersection(lineAB, mathPlane);
			this.setPointOfIntersection(lineBC, mathPlane);
			this.setPointOfIntersection(lineCA, mathPlane);
		});

		var lines = new THREE.LineSegments(pointsOfIntersection, new THREE.LineBasicMaterial({
			color: 0xffff00
		}));
		targetScene.add(lines);
	}

}